export const DROPZONE_MAX_VIDEO_MB_SIZE = 1024;
export const DROPZONE_MAX_VIDEO_FILES = 1;
export const DROPZONE_MAX_IMAGE_FILES = 10;
export const DROPZONE_MULTIPLE_VIDEO_FILES = false;
export const DROPZONE_MULTIPLE_IMAGE_FILES = true;

export const DROPZONE_IMAGE_FORMATS = {
    "image/jpeg": [".jpeg", ".JPEG"],
    "image/jpg": [".jpg", ".JPG"],
    "image/png": [".png", ".PNG"]
};

export const DROPZONE_VIDEO_FORMATS = {
    "video/mp4": [".mp4", ".MP4"],
    "video/mov": [".mov", ".MOV"]
};

export const DROPZONE_ALL_FORMATS = {
    "image/jpeg": [".jpeg", ".JPEG"],
    "image/png": [".png", ".PNG"],
    "video/mp4": [".mp4", ".MP4"],
    "video/mov": [".mov", ".MOV"]
};