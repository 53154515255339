import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Label, Row} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import {
    ADMIN_USER_SUB_PAGE,
    APP_ADMIN_PAGE, APP_CONFIG_DEFAULT
} from "../../config/config";
import Breadcrumb from "../../common/Breadcrumb";
import {AuthUserData} from "../../constants/models/Models";
import {useLocation, useNavigate} from "react-router-dom";
import {capitalize, getStatusBadgeCssClass} from "../../utils/utils";


const UserDetails = () => {
    document.title = ADMIN_USER_SUB_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;
    const navigate = useNavigate();
    const location = useLocation();
    const { authUser } = location.state || {};

    const [userData, setUserData] = useState<AuthUserData | null>(authUser);

    useEffect(() => {
        if (!authUser) {
            navigate(APP_ADMIN_PAGE.path);
        }
        setUserData(authUser);
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title={{label: APP_CONFIG_DEFAULT.title,  path: APP_CONFIG_DEFAULT.path}}
                                breadcrumbItem={{label: APP_ADMIN_PAGE.label, path: APP_ADMIN_PAGE.path}}
                                subBreadcrumbItem={{label:ADMIN_USER_SUB_PAGE.label, path:ADMIN_USER_SUB_PAGE.path}}/>
                    <Col lg={12} xl={12}>
                        <Card>
                            <CardBody>
                                <div className="card border shadow-none mb-5">
                                    <div className="card-header d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title rounded-circle btn-primary-subtle text-primary">
                                                    01
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">General Info</h5>
                                        </div>
                                    </div>
                                    <CardBody>
                                        <Row>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Id no  {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.uid?userData?.uid:''}</h5>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Name {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.displayName}</h5>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Email {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.email?userData?.email:""}</h5>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Roles {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.roles?userData?.roles.map(capitalize).join(", "):""}</h5>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Designation {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.designation}</h5>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Description {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.description}</h5>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </div>
                                <div className="card border shadow-none mb-5">
                                    <div className="card-header d-flex align-items-center">
                                    <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title rounded-circle btn-primary-subtle text-primary">
                                                    02
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">Activities</h5>
                                        </div>
                                    </div>
                                    <CardBody>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Created Date {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.createdDate}</h5>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Modified Date {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.modifiedDate}</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-2">
                                                    <Label className="text-muted mb-1">Last Login {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.lastLoginAt}</h5>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-2">
                                                    <p className="text-muted font-size-14 user-status-label mb-1">Status {" "}:</p>
                                                    <div className={`font-size-12 text-truncate ${getStatusBadgeCssClass(userData?.status!)}`}>{capitalize(userData?.status!)}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </div>
                                <div className="card border shadow-none mb-5">
                                    <div className="card-header d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div
                                                    className="avatar-title rounded-circle btn-primary-subtle text-primary">
                                                    03
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">Contact Info</h5>
                                        </div>
                                    </div>
                                    <CardBody>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Email {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.email}</h5>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Phone Number {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.phoneNumber}</h5>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Address 1 {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.address1}</h5>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Address 2 {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.address2}</h5>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">City {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.city}</h5>
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">State {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.state}</h5>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Post Code {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.postCode}</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Country {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.country.name}</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                </CardBody>
                            </div>
                        </CardBody>
                    </Card>
                    </Col>
                </Container>
            </div >
        </React.Fragment>
    );
};

export default UserDetails;