export enum RoleType {
    USER = 'user',
    // PREMIUM_USER = 'premium',
    // PREMIUM_PRO_USER = 'premium pro',
    ADMIN_USER = 'admin',
    SUPER_ADMIN_USER = 'super admin'
}

export enum UserStatus {
    ALL = 'all',
    PENDING = 'pending',
    ACTIVE = 'active',
    SUSPENDED = 'suspended',
    // DISABLED = 'disabled',
}