import {AuthUserData} from "../constants/models/Models";
import {RoleType, UserStatus} from "../constants/enums/Auth";

class AuthUserService {
    private SUPER_ADMIN_USER_ROLES: RoleType[] = [RoleType.USER, RoleType.ADMIN_USER, RoleType.SUPER_ADMIN_USER];
    private ADMIN_USER_ROLES: RoleType[] = [RoleType.USER, RoleType.ADMIN_USER];
    private USER_ROLES:RoleType[] = [RoleType.USER];

    isLoggedUserSuperAdmin = () => {
        const loggedInUser: AuthUserData = JSON.parse(localStorage.getItem("authUser")!);
        return loggedInUser && loggedInUser.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase() && loggedInUser.roles?.includes(RoleType.SUPER_ADMIN_USER) && loggedInUser.roles?.every(role => this.SUPER_ADMIN_USER_ROLES.includes(role));
    }

    isLoggedUserAdmin = () => {
        const loggedInUser: AuthUserData = JSON.parse(localStorage.getItem("authUser")!);
        return loggedInUser && loggedInUser.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase() && loggedInUser.roles?.includes(RoleType.ADMIN_USER) && loggedInUser.roles?.every(role => this.ADMIN_USER_ROLES.includes(role));
    }

    getLoggedAuthorizedUser = () => {
        const loggedInUser: AuthUserData = JSON.parse(localStorage.getItem("authUser")!);
        return loggedInUser;
    }

    isUserSuperAdmin = (authUser: AuthUserData) => {
        return authUser.roles?.includes(RoleType.SUPER_ADMIN_USER);
    }

    isUserAdmin = (authUser: AuthUserData) => {
        return authUser.roles?.includes(RoleType.ADMIN_USER);
    }
}


export default new AuthUserService();