import {Input} from "reactstrap";
import React from "react";

interface DefaultColumnFilterProps {
    column?: any;
    filterValue?: any;
    setFilter?: any;
    preFilteredRows?: any;
}

export const DefaultColumnFilter = (
    {
        column: {
            filterValue,
            setFilter,
            preFilteredRows,
            Header
        }
    }: DefaultColumnFilterProps) => {
    const count = preFilteredRows.length

    return (
        <React.Fragment>
            <Input placeholder={`Search ${Header}...`} value={filterValue || ''} onChange={(e) => setFilter(e.target.value || undefined)}/>
        </React.Fragment>
    );
};
