import React, {useState} from "react";
import RightSidebar from "./RightSidebar";
import NotificationDropdown from "./NotificationDropdown";
import ProfileMenu from "./ProfileMenu";

import {Offcanvas} from "reactstrap";
import scannerLogoSm from "../assets/images/scanner-logo-sm.png";
import logoDark from "../assets/images/scanner-logo.png";
import {Link} from "react-router-dom";
import {tToggle} from "../utils/utils";
import {APP_CONFIG_DEFAULT} from "../config/config";

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to={APP_CONFIG_DEFAULT.path} className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={scannerLogoSm} alt="" height="22"/>
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="22"/>
                  </span>
                </Link>
              </div>
              <button onClick={() => { tToggle(true); }} type="button" className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn" id="vertical-menu-btn">
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>
            <div className="d-flex">
              <NotificationDropdown/>
              <ProfileMenu/>
            </div>
          </div>
        </header>

        <Offcanvas isOpen={openDrawer} onClosed={onDrawerClose} direction={"end"}>
          <RightSidebar onClosed={onDrawerClose} />
        </Offcanvas>
      </React.Fragment>
  );
};

export default Header;