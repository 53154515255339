import axios, {AxiosInstance, AxiosResponse} from "axios";
import {jsonToHeaderQuery} from "../utils/utils";
import {
    INFERENCE_VIDEO_DOWNLOAD_ENDPOINT,
    INFERENCE_ENDPOINT,
    INFERENCE_PARAMS,
    VIDEO_INFERENCE_ENDPOINT,
    SERVER_BASE_URL_CONFIG,
} from "../utils/url_helper";

import {getUserXApiKey, headerContentType} from "../utils/auth-token-header";
import {ResponseType} from "../constants/enums/inference_enums";

class FetchService {
    private serverAPI: AxiosInstance;

    constructor() {
        this.serverAPI = axios.create({
            baseURL: SERVER_BASE_URL_CONFIG().apiBaseUrl,
            headers: {
                'Content-Type': headerContentType.JSON_TYPE,
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    inference = (data: any) => {
        const headerQuery = jsonToHeaderQuery(INFERENCE_PARAMS);
        return this.serverAPI.post(`/${INFERENCE_ENDPOINT}?${headerQuery}`, data, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    getVideoByUserId(userId:string): Promise<AxiosResponse<any>> {
        return this.serverAPI.get(`/${VIDEO_INFERENCE_ENDPOINT}/${userId}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    uploadVideo(data: any, headers: any): Promise<AxiosResponse<any>> {
        return this.serverAPI.post(`/${VIDEO_INFERENCE_ENDPOINT}`, data, headers);
    }

    downloadVideo(downloadUrl: string, responseType: ResponseType | null = null) {
        if (responseType) {
            return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
                headers: {
                    'X-API-KEY': getUserXApiKey()
                },
                responseType: responseType
            });
        }
        return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    downloadJSON(downloadUrl: string, responseType: ResponseType | null = null) {
        if (responseType) {
            return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
                headers: {
                    'X-API-KEY': getUserXApiKey()
                },
                responseType: responseType
            });
        }
        return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }
    deleteVideo = (data: any)=> {
        return this.serverAPI.delete(`/${VIDEO_INFERENCE_ENDPOINT}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            },
            data
        });
    }
}

export default new FetchService();