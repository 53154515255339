import React from 'react';
import {Col, Pagination, PaginationItem, PaginationLink, Row} from 'reactstrap';

interface DynamicPaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    pageSize: number;
    totalEntries: number;
}

const DynamicPagination = ({ totalPages, currentPage, onPageChange, pageSize, totalEntries }: DynamicPaginationProps) => {
    const handlePageClick = (page: number) => {
        onPageChange(page);
    };

    const prevPage = currentPage > 1 ? currentPage - 1 : null;
    const nextPage = currentPage < totalPages ? currentPage + 1 : null;
    const paginationItems = Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
        <PaginationItem key={pageNumber} active={currentPage === pageNumber}>
            <PaginationLink onClick={(e) => { e.preventDefault(); handlePageClick(pageNumber); }} className="page-link">
                {pageNumber}
            </PaginationLink>
        </PaginationItem>
    ));

    const startEntry = (currentPage - 1) * pageSize + 1;
    const endEntry = Math.min(currentPage * pageSize, totalEntries);

    return (
        <React.Fragment>
            <Row className="g-0">
                <Col sm={6}>
                    <div>
                        <p className="mb-sm-0">Showing {totalEntries > 0 ? startEntry : totalEntries} to {endEntry} of {totalEntries} records</p>
                    </div>
                </Col>

                <Col sm={6}>
                    <div className="float-sm-end">
                        <ul className="pagination pagination-rounded mb-sm-0">
                            <Pagination>
                                <PaginationItem disabled={!prevPage}>
                                    <PaginationLink onClick={() => handlePageClick(prevPage!)}>
                                        <i className="mdi mdi-chevron-left"></i>
                                    </PaginationLink>
                                </PaginationItem>
                                {paginationItems}
                                <PaginationItem disabled={!nextPage}>
                                    <PaginationLink onClick={() => handlePageClick(nextPage!)}>
                                        <i className="mdi mdi-chevron-right"></i>
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </ul>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DynamicPagination;
