import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from "prop-types";

const ProfileAvatarEditor = ({image, avatarRef, sliderValue}) => {
    return (
        <React.Fragment>
            <AvatarEditor
                ref={avatarRef}
                image={image}
                style={{width: "100%", height: "100%"}}
                border={20}
                borderRadius={150}
                color={[0, 0, 0, 0.72]}
                scale={sliderValue / 10}
                rotate={0}
            />
        </React.Fragment>
    );
};
export default ProfileAvatarEditor;

ProfileAvatarEditor.propTypes = {
    image: PropTypes.string,
    avatarRef: PropTypes.object,
    sliderValue: PropTypes.number,
};