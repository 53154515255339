import React, {useEffect, useRef, useState} from "react";
import {
    Col,
    Container,
    Row,
    Button,
    Input,
    FormFeedback,
    Form,
    Label,
    Alert
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import avatar from "../../assets/images/users/avatar.png";
import { CardBody, Card } from "reactstrap";
import {
    APP_CONFIG_DEFAULT,
    SETTINGS_MENU_USER_EDIT
} from "../../config/config";
import Breadcrumb from "../../common/Breadcrumb";
import AvatarCropperModal from "./AvatarCropperModal";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import { editUserSettingProfile } from "../../slices/Auth/Profile/thunk";
import * as Yup from "yup";
import { useFormik } from "formik";
import {isValidEmail} from "../../utils/utils";
import {AuthUserData} from "../../constants/models/Models";
import {UserStatus} from "../../constants/enums/Auth";
import AuthUserService from "../../services/AuthUserService";

const initUserData: AuthUserData = {
    uid: "",
    email: "",
    roles: [],
    createdDate: null,
    lastLoginAt: null,
    status: UserStatus.PENDING,
    displayName: "",
    photoURL: "",
    phoneNumber: "",
    designation: "",
    description: "",
    addressInfo: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        postCode: "",
        country: {
            name: "",
            code: "",
        }
    }
};

const UserSetting = () => {
    document.title = SETTINGS_MENU_USER_EDIT.label + " | " + APP_CONFIG_DEFAULT.title;

    const [image, setImage] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [userData, setUserData] = useState<AuthUserData>(AuthUserService.getLoggedAuthorizedUser());
    const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);

    const dispatch = useDispatch();

    const selectLayoutProperties = createSelector(
        (state: any) => state.profile,
        (layout) => ({
            error: layout.error,
            success: layout.success,
        })
    );

    const {error, success} = useSelector(selectLayoutProperties);

    useEffect(() => {
        loadCurrentUserDetails();
        }, [dispatch, success, error]);

    const loadCurrentUserDetails = () => {
        const authUser = AuthUserService.getLoggedAuthorizedUser()
        if (authUser) {
            setUserData(authUser);
        } else {
            setUserData(initUserData);
        }
    };

    const updateUserPhotoFileUrl = async (photoUrl: string) => {
        const result = await fetch(photoUrl);
        const profilePicBlob: Blob = await result.blob();
        const croppedAvatar = blobToFile(profilePicBlob);
        setSelectedPhoto(croppedAvatar);
        setUserData({...userData, photoURL: photoUrl});
    }

    const blobToFile = (blob: Blob): File => {
        const fileName = "profile_avatar" + "." + blob.type.split('/')[1];
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    };

    const handleFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const imageFile = files[0];
            setImage(URL.createObjectURL(imageFile));
            setModalOpen(true);
        }
    };

    function handleSubmit(event: any) {
        const updatedUserData: AuthUserData = formikValidation.values;
        dispatch(editUserSettingProfile(updatedUserData, selectedPhoto));
        loadCurrentUserDetails();
    }

    const validationSchema = Yup.object({
        displayName: Yup.string().trim().required("Please enter your name"),
        // email: Yup.string().trim().email('Invalid email address').required('Email is required').test('custom', "Invalid email address", (value) => isValidEmail(value)),
        // phoneNumber: Yup.string().trim().required("Please enter your phone number"),
        // designation: Yup.string().trim().required("Please enter your designation"),
        // description: Yup.string().trim().required("Please enter your description"),
        // addressInfo: Yup.object().shape({
        //     address1: Yup.string().trim().required('Please enter your Address 1'),
        //     // address2: Yup.string().trim().required('Please enter your Address 2'),
        //     city: Yup.string().trim().required('Please enter your city'),
        //     state: Yup.string().trim().required('Please enter your state'),
        //     postCode: Yup.string().trim().required('Please enter your Post Code'),
        //     country: Yup.object().shape({
        //         name: Yup.string().trim().required('Please enter your country'),
        //         code: Yup.string().trim().required('Please enter your country code'),
        //     }),
        // })
    });

    const formikValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: userData.email || "",
            roles: userData.roles,
            status: userData.status,
            displayName: userData.displayName || "",
            photoURL: userData.photoURL || avatar,
            phoneNumber: userData.phoneNumber || "",
            designation: userData.designation || "",
            description: userData.description || "",
            createdDate: userData.createdDate,
            lastLoginAt: userData.lastLoginAt,
            modifiedDate: userData.modifiedDate,

            addressInfo: userData.addressInfo || {
                address1: "",
                address2: "",
                city: "",
                state: "",
                postCode: "",
                country: {
                    name: "",
                    code: "",
                }
            },
            uid: userData.uid || "",
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title={{label: APP_CONFIG_DEFAULT.title,  path: APP_CONFIG_DEFAULT.path}}
                                breadcrumbItem={{label:SETTINGS_MENU_USER_EDIT.label, path:SETTINGS_MENU_USER_EDIT.path}} />
                    <Row>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            {error ? <Alert color="danger">{error.message ? error.message : error}</Alert> : null}
                            {success ? <Alert color="success">{success}</Alert> : null}
                        </div>

                    <div className="col-xxl-3 col-lg-4">
                        <Card>
                            <CardBody>
                                <div className="profile-bg position-relative overflow-hidden rounded-top"
                                     style={{height: "150px"}}>
                                    <div className="bg-overlay bg-dark bg-gradient"></div>
                                    <div className="position-relative p-3 text-end">
                                        {/*<button type="button" className="btn btn-primary btn-sm"><i className="mdi mdi-pencil me-1"></i> Edit</button>*/}
                                    </div>
                                </div>
                                <div className="text-center">
                                    <div className="position-relative d-inline-block mx-auto mt-n5 mb-4">
                                        <div className="avatar-lg">
                                            <img src={userData.photoURL} alt=""
                                                 className="rounded-circle img-thumbnail"/>
                                        </div>
                                        <div className="d-block position-absolute bottom-0 end-0">
                                            <div className="avatar-sm">
                                                <div className="avatar-title rounded-circle bg-light text-primary"
                                                     onClick={handleFileClick}>
                                                    <i className="mdi mdi-pencil"></i>
                                                </div>
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/jpg, image/jpeg, image/png"
                                                ref={fileInputRef}
                                                style={{display: 'none'}} // Hide the file input
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <AvatarCropperModal
                                            modalOpen={modalOpen}
                                            image={image!}
                                            setImage={setImage}
                                            setModalOpen={setModalOpen}
                                            updateUserPhotoFileUrl={updateUserPhotoFileUrl}
                                        />
                                    </div>
                                    <h5 className="font-size-16 mb-1">{userData.displayName}</h5>
                                    <p className="text-muted mb-0">{userData.designation}</p>
                                </div>
                                <hr className="my-4"/>
                                <div>
                                    <h5 className="font-size-16">Info {" "}:</h5>
                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Name {" "}:</p>
                                        <h5 className="font-size-14 text-truncate">{userData.displayName}</h5>
                                    </div>

                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Email {" "}:</p>
                                        <h5 className="font-size-14 text-truncate">{userData.email}</h5>
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Location {" "}:</p>
                                        <h5 className="font-size-14 text-truncate">{userData.addressInfo?.city} {userData.addressInfo?.country.name ? ", " + userData.addressInfo?.country.name : ""}</h5>
                                    </div>
                                </div>

                                </CardBody>
                        </Card>
                    </div>
                    <div className="col-xxl-9 col-lg-8">
                        <Card>
                            <CardBody>
                                <h5 className="card-title mb-4">Setting</h5>
                                <Form className="form-horizontal"
                                      onSubmit={e => {
                                          e.preventDefault();
                                          formikValidation.handleSubmit();
                                          return false;
                                      }}>
                                    <Input
                                        name="photoURL"
                                        type="hidden"
                                        onChange={formikValidation.handleChange}
                                        onBlur={formikValidation.handleBlur}
                                        value={formikValidation.values.photoURL || avatar}
                                        invalid={formikValidation.touched.photoURL && !!formikValidation.errors.photoURL}
                                    />
                                    <div className="card border shadow-none mb-5">
                                        <div className="card-header d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle btn-primary-subtle text-primary">
                                                        01
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title">General Info</h5>
                                            </div>
                                        </div>
                                        <CardBody>
                                            <div>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="displayName">Name</Label>
                                                            <Input
                                                                name="displayName"
                                                                className="form-control"
                                                                placeholder="Enter Name"
                                                                type="text"
                                                                onChange={formikValidation.handleChange}
                                                                onBlur={formikValidation.handleBlur}
                                                                value={formikValidation.values.displayName || ""}
                                                                invalid={formikValidation.touched.displayName && !!formikValidation.errors.displayName}
                                                                rows={3}
                                                            />
                                                            {formikValidation.touched.displayName && formikValidation.errors.displayName && (
                                                                <FormFeedback
                                                                    type="invalid">{formikValidation.errors.displayName}</FormFeedback>)}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="designation">Position</Label>
                                                            <Input
                                                                name="designation"
                                                                className="form-control"
                                                                placeholder="Enter designation"
                                                                type="text"
                                                                onChange={formikValidation.handleChange}
                                                                onBlur={formikValidation.handleBlur}
                                                                value={formikValidation.values.designation || ""}
                                                                invalid={formikValidation.touched.designation && !!formikValidation.errors.designation}
                                                            />
                                                            {formikValidation.touched.designation && formikValidation.errors.designation && (
                                                                <FormFeedback type="invalid">{formikValidation.errors.designation}</FormFeedback>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="description">Description</Label>
                                                        <Input
                                                            name="description"
                                                            className="form-control"
                                                            placeholder="Enter Description"
                                                            type="textarea"
                                                            onChange={formikValidation.handleChange}
                                                            onBlur={formikValidation.handleBlur}
                                                            value={formikValidation.values.description || ""}
                                                            invalid={formikValidation.touched.description && !!formikValidation.errors.description}
                                                            rows={3}
                                                        />
                                                        {formikValidation.touched.description && formikValidation.errors.description && (
                                                            <FormFeedback type="invalid">{formikValidation.errors.description}</FormFeedback>)}
                                                    </div>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </div>

                                    <div className="card border shadow-none mb-5">
                                        <div className="card-header d-flex align-items-center">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle btn-primary-subtle text-primary">
                                                        02
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title">Contact Info</h5>
                                            </div>
                                        </div>

                                        <CardBody>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="phoneNumber">Phone Number</Label>
                                                        <Input
                                                            name="phoneNumber"
                                                            className="form-control"
                                                            placeholder="Enter Phone Number"
                                                            type="text"
                                                            onChange={formikValidation.handleChange}
                                                            onBlur={formikValidation.handleBlur}
                                                            value={formikValidation.values.phoneNumber || ""}
                                                            invalid={formikValidation.touched.phoneNumber && !!formikValidation.errors.phoneNumber}
                                                        />
                                                        {formikValidation.touched.phoneNumber && formikValidation.errors.phoneNumber && (
                                                            <FormFeedback type="invalid">{formikValidation.errors.phoneNumber}</FormFeedback>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="addressInfo.address1">Address 1</Label>
                                                        <Input
                                                            name="addressInfo.address1"
                                                            className="form-control"
                                                            placeholder="Enter Address Line 1"
                                                            type="text"
                                                            onChange={formikValidation.handleChange}
                                                            onBlur={formikValidation.handleBlur}
                                                            value={formikValidation.values.addressInfo?.address1 || ""}
                                                            invalid={formikValidation.touched.addressInfo?.address1 && !!formikValidation.errors.addressInfo?.address1}
                                                        />
                                                        {formikValidation.touched.addressInfo?.address1 && formikValidation.errors.addressInfo?.address1 && (
                                                            <FormFeedback type="invalid">{formikValidation.errors.addressInfo?.address1}</FormFeedback>
                                                        )}
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="addressInfo.address2">Address 2</Label>
                                                        <Input
                                                            name="addressInfo.address2"
                                                            className="form-control"
                                                            placeholder="Enter Address Line 2"
                                                            type="text"
                                                            onChange={formikValidation.handleChange}
                                                            onBlur={formikValidation.handleBlur}
                                                            value={formikValidation.values.addressInfo?.address2 || ""}
                                                            invalid={formikValidation.touched.addressInfo?.address2 && !!formikValidation.errors.addressInfo?.address2}
                                                        />
                                                        {formikValidation.touched.addressInfo?.address2 && formikValidation.errors.addressInfo?.address2 && (
                                                            <FormFeedback type="invalid">{formikValidation.errors.addressInfo?.address2}</FormFeedback>
                                                        )}
                                                    </div>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="addressInfo.city">City</Label>
                                                    <Input
                                                        name="addressInfo.city"
                                                        className="form-control"
                                                        placeholder="Enter City"
                                                        type="text"
                                                        onChange={formikValidation.handleChange}
                                                        onBlur={formikValidation.handleBlur}
                                                        value={formikValidation.values.addressInfo?.city || ""}
                                                        invalid={formikValidation.touched.addressInfo?.city && !!formikValidation.errors.addressInfo?.city}
                                                    />

                                                    {formikValidation.touched.addressInfo?.city && formikValidation.errors.addressInfo?.city && (
                                                        <FormFeedback type="invalid">{formikValidation.errors.addressInfo?.city}</FormFeedback>
                                                    )}
                                                </div>
                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="addressInfo.state">State</Label>
                                                        <Input
                                                            name="addressInfo.state"
                                                            className="form-control"
                                                            placeholder="Enter State"
                                                            type="text"
                                                            onChange={formikValidation.handleChange}
                                                            onBlur={formikValidation.handleBlur}
                                                            value={formikValidation.values.addressInfo?.state || ""}
                                                            invalid={formikValidation.touched.addressInfo?.state && !!formikValidation.errors.addressInfo?.state}
                                                        />

                                                        {formikValidation.touched.addressInfo?.state && formikValidation.errors.addressInfo?.state && (
                                                            <FormFeedback type="invalid">{formikValidation.errors.addressInfo?.state}</FormFeedback>
                                                        )}
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="addressInfo.postCode">Post Code</Label>
                                                        <Input
                                                            name="addressInfo.postCode"
                                                            className="form-control"
                                                            placeholder="Enter Post Code"
                                                            type="text"
                                                            onChange={formikValidation.handleChange}
                                                            onBlur={formikValidation.handleBlur}
                                                            value={formikValidation.values.addressInfo?.postCode || ""}
                                                            invalid={formikValidation.touched.addressInfo?.postCode && !!formikValidation.errors.addressInfo?.postCode}
                                                        />

                                                        {formikValidation.touched.addressInfo?.postCode && formikValidation.errors.addressInfo?.postCode && (
                                                            <FormFeedback type="invalid">{formikValidation.errors.addressInfo?.postCode}</FormFeedback>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="addressInfo.country.name">Country</Label>
                                                    <Input
                                                        name="addressInfo.country.name"
                                                        className="form-control"
                                                        placeholder="Enter City"
                                                        type="text"
                                                        onChange={formikValidation.handleChange}
                                                        onBlur={formikValidation.handleBlur}
                                                        value={formikValidation.values.addressInfo?.country?.name || ""}
                                                        invalid={formikValidation.touched.addressInfo?.country?.name && !!formikValidation.errors.addressInfo?.country?.name}
                                                    />
                                                    {formikValidation.touched.addressInfo?.country?.name && formikValidation.errors.addressInfo?.country?.name && (
                                                        <FormFeedback type="invalid">{formikValidation.errors.addressInfo?.country?.name}</FormFeedback>
                                                        )}
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </div>
                                    <div className="text-end">
                                        <Button type="submit" color="btn btn-primary w-sm">Save</Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div >
        </React.Fragment>
    );
};

export default UserSetting;