import {UrlConfig} from "../constants/types";

export const SERVER_BASE_URL_CONFIG = (): UrlConfig => {
    const apiBaseUrlConfig: UrlConfig = process.env.REACT_APP_API_BASE_URL ? {apiBaseUrl: process.env.REACT_APP_API_BASE_URL} : require('./url_config.json')[process.env.NODE_ENV!] ? require('./url_config.json')[process.env.NODE_ENV!] : require('./url_config.json').development;
    return apiBaseUrlConfig;
};


export const SERVER_BASE_URL = "http://127.0.0.1";

// End points
// export const INFERENCE_ENDPOINT = 'brands/food/infer';
export const INFERENCE_ENDPOINT = 'brands/infer';
export const INFERENCE_PARAMS = {
    min_score:90,
    draw_bbox:1,
};

// export const INFERENCE_VIDEO_ENDPOINT = 'video/demo';

// API Key Endpoint
export const API_KEY_ENDPOINT = 'apikey'; // apikey for get keys
export const API_KEY_VALID_ENDPOINT = API_KEY_ENDPOINT + '/valid';

// Video Endpoints
export const VIDEO_INFERENCE_ENDPOINT = 'video';
export const INFERENCE_VIDEO_DOWNLOAD_ENDPOINT = 'download';

// test
export const IISRI_DEAKIN_NAME = 'IISRI';
export const IHT_DEAKIN_NAME = 'IHT';
export const DEAKIN_NAME = 'Deakin University';
export const IISRI_DEAKIN_URL = 'https://iisri.deakin.edu.au';
export const IHT_DEAKIN_URL = 'https://iht.deakin.edu.au/globe';
export const DEAKIN_URL = 'https://deakin.edu.au';

export const LOGIN = "/api/auth/login";
export const SIGNUP = "/api/auth/signup";
export const FORGOT_PASSWORD = "/api/auth/forgot-password";
export const RESET_PASSWORD = "/api/auth/reset-password";



//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";
export const ADD_INVOICE = "/add/invoice"
export const DELETE_INVOICE = "/delete/invoice"
export const UPDATE_INVOICE = "/update/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";
