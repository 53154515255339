import {FirebaseOptions, initializeApp} from 'firebase/app';
import { FirestoreService } from '../services/FirestoreService';
import {FirebaseApp} from "@firebase/app";
import FirebaseAuthService from "../services/FirebaseAuthService";

let _firebaseAuthService: FirebaseAuthService | null = null;
let _firestoreService: FirestoreService | null = null;

const initializeFirebaseServices = (config: FirebaseOptions) => {
  const app: FirebaseApp = initializeApp(config);

  if (!_firebaseAuthService) {
    _firebaseAuthService = new FirebaseAuthService(app);
  }

  if (!_firestoreService) {
    _firestoreService = new FirestoreService(app);
  }
};

const getFirebaseAuthService = () => {
  return _firebaseAuthService;
};

const getFirestoreService = () => {
  return _firestoreService;
};

export { initializeFirebaseServices, getFirebaseAuthService, getFirestoreService };