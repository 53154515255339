import React, {useState, useEffect} from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

//import withRouter
import withRouter from "./withRouter";
// users
import avatar from "../assets/images/users/avatar.png";

//redux
import {Link} from "react-router-dom";
import {
    APP_ADMIN_PAGE, AUTH_LOGOUT_PAGE,
    SETTINGS_MENU_USER_EDIT,
    SETTINGS_MENU_USER_PROFILE
} from "../config/config";
import {AuthUserData} from "../constants/models/Models";
import AuthUserService from "../services/AuthUserService";

const ProfileMenu = () => {
    const authUserService = AuthUserService;
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState<boolean>(false);
    const [userData, setUserData] = useState<AuthUserData | null>(authUserService.getLoggedAuthorizedUser());

    useEffect(() => {
        const authUser = authUserService.getLoggedAuthorizedUser();
        if (authUser) {
            setUserData(authUser);
        }
    });

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item user text-start d-flex align-items-center"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={userData?.photoURL ? userData.photoURL : avatar}
                        alt="Profile Picture"
                    />
                    <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{userData?.displayName}</span>
            <span className="user-sub-title">{userData?.email}</span>
          </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
                    <div className="p-3 bg-primary border-bottom">
                        <h6 className="mb-0 text-white">{userData?.displayName}</h6>
                        <p className="mb-0 font-size-11 text-white-50 fw-semibold">
                            {userData?.email}
                        </p>
                    </div>

                    {(authUserService.isLoggedUserSuperAdmin() || authUserService.isLoggedUserAdmin()) &&
                        <Link to={APP_ADMIN_PAGE.path}>
                            <DropdownItem className="d-flex align-items-center bg-primary-subtle">
                                <i className="mdi mdi-security text-muted font-size-16 align-middle me-1"></i>{" "}
                                <span className="align-middle">
                                    {APP_ADMIN_PAGE.label}
                                </span>
                            </DropdownItem>
                        </Link>
                    }

                    {(authUserService.isLoggedUserSuperAdmin() || authUserService.isLoggedUserAdmin()) &&
                    <DropdownItem divider/>
                    }

                    <Link to={SETTINGS_MENU_USER_PROFILE.path}>
                        <DropdownItem>
                            <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>{" "}
                            <span className="align-middle">
                              {SETTINGS_MENU_USER_PROFILE.label}
                            </span>
                        </DropdownItem>
                    </Link>

                    <Link to={SETTINGS_MENU_USER_EDIT.path}>
                      <DropdownItem className="d-flex align-items-center">
                            <i className="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i>{" "}
                        <span className="align-middle">
                          {SETTINGS_MENU_USER_EDIT.label}
                        </span>
                          {!userData?.displayName && <span className="badge badge-success-subtle ms-auto">New</span>}
                      </DropdownItem>
                    </Link>

                    <Link to={AUTH_LOGOUT_PAGE.path}>
                      <DropdownItem>
                        <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{" "}
                        <span className="align-middle">
                          {AUTH_LOGOUT_PAGE.label}
                        </span>
                      </DropdownItem>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default withRouter(ProfileMenu);
