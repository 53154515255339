import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import {ADMIN_USER_EDIT_SUB_PAGE, ADMIN_USER_SUB_PAGE, APP_ADMIN_PAGE, APP_CONFIG_DEFAULT} from "../../config/config";
import Breadcrumb from "../../common/Breadcrumb";
import {capitalize} from "../../utils/utils";
import {AdminPanelUserAction, AuthUserData} from "../../constants/models/Models";
import classnames from "classnames";
import AdminDataTable from "./AdminDataTable";
import FirebaseAuthService from "../../services/FirebaseAuthService";
import {getFirebaseAuthService} from "../../utils/firebase_utils";
import {useNavigate} from "react-router-dom";
import {UserStatus} from "../../constants/enums/Auth";
import {AdminPanelModal} from "../Common/Modals/AdminPanelModal";
import AuthUserService from "../../services/AuthUserService";
import AuthAPIKeyService from "../../services/AuthAPIKeyService";
import {notifyError, notifySuccess} from "../../services/NotificationService";
import {AxiosError} from "axios";

const AdminPanel = () => {
    document.title = APP_ADMIN_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userList, setUserList] = useState<AuthUserData[]>([]);
    const [currentUserAction, setCurrentUserAction] = useState<AdminPanelUserAction | null>(null);
    const [userStatusTab, setUserStatusTab] = useState<UserStatus>(UserStatus.ALL);
    const navigate = useNavigate();

    useEffect(() => {
        loadUsers().then();
        }, []);

    const handleToggleModal = () => setIsModalOpen(!isModalOpen);

    const loadUsers = async () => {
        try {
            const firebaseAuthService: FirebaseAuthService | null = getFirebaseAuthService();
            const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser()
            if (AuthUserService.isLoggedUserSuperAdmin() || AuthUserService.isLoggedUserAdmin()) {
                const users: AuthUserData[] = await firebaseAuthService!.getUserDetails()
                const sortedUsers: AuthUserData[] = users.sort((a, b) => new Date(a.modifiedDate).getTime() - new Date(b.modifiedDate).getTime());
                setUserList(sortedUsers);
            } else {
                setUserList([]);
            }
            setIsDataLoading(false)
        } catch (error) {
            setUserList([]);
        }
    }

    const toggleUserStatusTab = (tab: UserStatus) => {
        if (userStatusTab !== tab) setUserStatusTab(tab);
    };
    const handleViewUser = (authUser: AuthUserData) => {
        navigate(ADMIN_USER_SUB_PAGE.path, { state: { authUser }});
    }

    const handleUpdateUser = (authUser: AuthUserData) => {
        navigate(ADMIN_USER_EDIT_SUB_PAGE.path, { state: { authUser }});
    }

    const handleUserAction = (userAction: AdminPanelUserAction) => {
        setCurrentUserAction(userAction);
        handleToggleModal();
        document.body.classList.add("no_padding");
    }

    const isValidAccessXApiKey = async (accessXApiKey: string) => {
        try {
            const response = await AuthAPIKeyService.validateApiKey({api_key: accessXApiKey});
            if (response && response.status == 200) {
                const data = response.data;
                return data['data'] ? data['data']['is_valid'] : false;
            } else if (response && (response.status == 400 || response.status == 401)) {
                return false;
            }
            return false;
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response && (error.response.status == 400 || error.response.status == 401 || error.code === 'ERR_NETWORK')) {
                    return false;
                }
            }
            return false;
        }
    }
    const handleAdminPanelModalAction = async (userAction: AdminPanelUserAction) => {
        try {
            const firebaseAuthService: FirebaseAuthService | null = getFirebaseAuthService();
            if (userAction.action?.toLowerCase() === UserStatus.ACTIVE.toLowerCase()) {
                const requestData = { owner: userAction.authUser.email };
                const response = await AuthAPIKeyService.generateApiKey(requestData);
                if (response && response.status == 201) {
                    const data = response.data; // {"api_key": api_key, "message": "Please save this secret key somewhere safe and accessible."}
                    const generatedApiKey = data["data"] ? data["data"]["api_key"] : data["api_key"]

                    // check the generated API Key is valid
                    const validAccessXApiKey = await isValidAccessXApiKey(generatedApiKey);
                    if (validAccessXApiKey) {
                        const result = await firebaseAuthService!.updateUserStatusAndXApiKey(userAction.authUser, userAction.action, generatedApiKey);
                        if (result) {
                            notifySuccess(result);
                        }
                    } else {
                        notifyError(`Unable to proceed due to invalid generated API Key. Please try again.`);
                    }
                }
            } else if (userAction.action?.toLowerCase() === UserStatus.SUSPENDED.toLowerCase()) {
                // check the generated API Key is valid
                const validAccessXApiKey = await isValidAccessXApiKey(userAction.authUser?.accessXApiKey!);
                if (validAccessXApiKey) {
                    const requestData = { api_key: userAction.authUser.accessXApiKey};
                    const response = await AuthAPIKeyService.deleteApiKey(requestData);
                    if (response && response.status == 200) {
                        const result = await firebaseAuthService!.updateUserStatusAndXApiKey(userAction.authUser, userAction.action, "");
                        if (result) {
                            notifySuccess(result);
                        }
                    }
                } else {
                    notifyError(`Unable to proceed due to invalid API Key of user. Please try again.`);
                }
            }
            handleToggleModal();
            setCurrentUserAction(null);
        } catch (error) {
            setCurrentUserAction(currentUserAction);
            handleToggleModal();
            if (error instanceof AxiosError) {
                if (error.response && error.response.status === 400) {
                    const data = error.response.data;
                    notifyError(data['data'] ? data['data']['message']: data['message']);
                } else {
                    notifyError(`Unable to proceed due to ${error.message}. Please try again.`);
                }
            } else {
                notifyError(`Unable to proceed. Please try again.`);
            }
        }
        loadUsers().then();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title={{label: APP_CONFIG_DEFAULT.title, path: APP_CONFIG_DEFAULT.path}}
                                breadcrumbItem={{label: APP_ADMIN_PAGE.label, path: APP_ADMIN_PAGE.path}}/>
                    <Col lg={12} xl={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Nav tabs className="nav-tabs-custom mb-4">
                                        <NavItem>
                                            <NavLink className={classnames({active: userStatusTab === UserStatus.ALL})} onClick={() => toggleUserStatusTab(UserStatus.ALL)}>{capitalize(UserStatus.ALL)}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({active: userStatusTab === UserStatus.ACTIVE})} onClick={() => toggleUserStatusTab(UserStatus.ACTIVE)}>{capitalize(UserStatus.ACTIVE)}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({active: userStatusTab === UserStatus.PENDING})} onClick={() => toggleUserStatusTab(UserStatus.PENDING)}>{capitalize(UserStatus.PENDING)}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({active: userStatusTab === UserStatus.SUSPENDED})} onClick={() => toggleUserStatusTab(UserStatus.SUSPENDED)}>{capitalize(UserStatus.SUSPENDED)}</NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={userStatusTab}>
                                        <TabPane tabId={UserStatus.ALL}>
                                            <AdminDataTable isDataLoading={isDataLoading} users={userList} onViewUser={handleViewUser} onUpdateUser={handleUpdateUser} onHandleUserAction={handleUserAction}/>
                                        </TabPane>
                                    </TabContent>

                                    <TabContent activeTab={userStatusTab}>
                                        <TabPane tabId={UserStatus.ACTIVE}>
                                            <AdminDataTable isDataLoading={isDataLoading} users={userList.filter(user => user.status?.toLowerCase() === UserStatus.ACTIVE)} onViewUser={handleViewUser} onUpdateUser={handleUpdateUser} onHandleUserAction={handleUserAction}/>
                                        </TabPane>
                                    </TabContent>

                                    <TabContent activeTab={userStatusTab}>
                                        <TabPane tabId={UserStatus.PENDING}>
                                            <AdminDataTable isDataLoading={isDataLoading} users={userList.filter(user => user.status?.toLowerCase() === UserStatus.PENDING)} onViewUser={handleViewUser} onUpdateUser={handleUpdateUser} onHandleUserAction={handleUserAction}/>
                                        </TabPane>
                                    </TabContent>

                                    <TabContent activeTab={userStatusTab}>
                                        <TabPane tabId={UserStatus.SUSPENDED}>
                                            <AdminDataTable isDataLoading={isDataLoading} users={userList.filter(user => user.status?.toLowerCase() === UserStatus.SUSPENDED)}
                                                            onViewUser={handleViewUser} onUpdateUser={handleUpdateUser} onHandleUserAction={handleUserAction}/>
                                        </TabPane>
                                    </TabContent>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Container>
                {currentUserAction &&
                    <AdminPanelModal isModalOpen={isModalOpen} toggleModal={handleToggleModal} userActionData={currentUserAction!} handleAction={handleAdminPanelModalAction}/>
                }
            </div >
        </React.Fragment>
    );
};

export default AdminPanel;