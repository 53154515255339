import {Button, Modal} from "reactstrap";
import React, {useRef, useState} from "react";
import {Slider} from "@mui/material";
import ProfileAvatarEditor from "./ProfileAvatarEditor";
import AvatarEditor from "react-avatar-editor";

export interface CropperModalProps {
    image: string;
    modalOpen: boolean;
    setImage: (image: string) => void;
    setModalOpen: (modalOpen: boolean) => void;
    updateUserPhotoFileUrl: (photoUrl: string) => void;
}

const AvatarCropperModal = ({image, modalOpen, setImage, setModalOpen, updateUserPhotoFileUrl}: CropperModalProps) => {
    const inputProfilePicRef = useRef<HTMLInputElement>(null);
    const [sliderValue, setSliderValue] = useState<number>(10);
    const cropAvatarRef = useRef<AvatarEditor>(null);
    const handleInputProfilePicture = () => {
        if (inputProfilePicRef.current) {
            inputProfilePicRef.current.value = '';
            inputProfilePicRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSliderValue(10);
        const files = e.target.files;
        if (files && files.length > 0) {
            const imageFile = files[0];
            setImage(URL.createObjectURL(imageFile));
            setModalOpen(true);
        }
    };

    const handleSave = async () => {
        if (cropAvatarRef.current) {
            const dataUrl = cropAvatarRef.current.getImage().toDataURL();
            updateUserPhotoFileUrl(dataUrl);
            setModalOpen(false);
        }
    };

    return (
        <Modal isOpen={modalOpen}>
            <div className="modal-header">
                <h5 className="modal-title">Crop profile picture</h5>
                <button type="button" className="btn-close"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                        aria-label="Close"
                ></button>
            </div>

            <div className="modal-body">
                <Button color="btn btn-primary-subtle" onClick={handleInputProfilePicture}>
                    <i className="uil uil-plus"></i> Upload photo
                </Button>
                <input
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    ref={inputProfilePicRef}
                    style={{display: 'none'}} // Hide the file input
                    onChange={handleFileChange}
                />
                <hr/>
                <ProfileAvatarEditor image={image} avatarRef={cropAvatarRef} sliderValue={sliderValue} />

                <Slider
                    min={10}
                    max={50}
                    sx={{
                        margin: "0 auto",
                        width: "100%",
                        color: "#beb7b7"
                    }}
                    size="medium"
                    defaultValue={sliderValue}
                    value={sliderValue}
                    onChange={(e:any) => setSliderValue(e.target!.value)}
                />
            </div>
            <div className="modal-footer">
                <Button type="button" color="btn btn-light" data-bs-dismiss="modal"
                        onClick={() => {
                            setModalOpen(false);
                        }}>Cancel</Button>
                <Button type="button" color="btn btn-primary" onClick={handleSave}>Save</Button>
            </div>
        </Modal>
    );
}

export default AvatarCropperModal;