import React, {useEffect, useState} from "react";
import {APP_CONFIG_DEFAULT, APP_INFERENCE_PAGE} from "../../config/config";

//redux
import FetchService from "../../services/FetchService";
import withRouter from "../../common/withRouter";
import {capitalize, formatBytes, formatEpochTimestampDate, getFileNameFromUrl} from "../../utils/utils";
import {AxiosError, AxiosProgressEvent} from "axios";
import {AuthUserData} from "../../constants/models/Models";
import AuthUserService from "../../services/AuthUserService";
import {InferenceState} from "../../constants/enums/inference_enums";
import {CategoryProps, CountryProps, ProcessedVideoStateData} from "../../constants/types";
import {ActiveTabType} from "../../constants/enums/layout";
import {notifyError, notifySuccess} from "../../services/NotificationService";
import {DROPZONE_MULTIPLE_VIDEO_FILES} from "../../constants/constants";
import {getUserXApiKey, headerContentType} from "../../utils/auth-token-header";
import {Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import Breadcrumbs from "../../common/Breadcrumb";
import VideoInferenceDataTableActionMenu from "./VideoInferenceDataTableActionMenu";
import classnames from "classnames";
import {InferenceDataTable} from "./InferenceDataTable";
import {DeleteModal} from "../Common/Modals/DeleteModal";
import {UserStatus} from "../../constants/enums/Auth";
import {downloadAllProcessedVideoData} from "../../utils/video_inference_utils";

const Inference = () => {
    document.title = APP_INFERENCE_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;
    const [isLoading, setIsLoading] = useState(true);
    const [activeBackendMessage, setActiveBackendMessage] = useState('');
    const [videoInferences, setVideoInferences] = useState<Array<ProcessedVideoStateData>>([]);
    const [activeTab, setActiveTab] = useState<ActiveTabType>(ActiveTabType.ALL);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [uploadPercentage, setUploadPercentage] = useState<number>(0);
    const [processFileStatus, setProcessFileStatus] = useState<InferenceState>(InferenceState.SELECTED);
    const [deleteInferenceVideoModal, setDeleteInferenceVideoModal] = useState<boolean>(false);
    const [deleteAllInferenceVideoModal, setDeleteAllInferenceVideoModal] = useState<boolean>(false);
    const [selectedProcessedInference, setSelectedProcessedInference] = useState<ProcessedVideoStateData | null>(null);
    const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
    const [activeBackend, setActiveBackend] = useState<boolean>(authUser.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase() && (authUser?.accessXApiKey !== "" || authUser.accessXApiKey !== undefined));

    useEffect(() => {
        fetchData(authUser?.uid).then(); // Initial fetch
        const intervalId = setInterval(() => {
            const hasNotCompleted = videoInferences.some(item => item.state !== InferenceState.COMPLETED);
            if (hasNotCompleted) {
                fetchData(authUser?.uid).then();
            }
        }, 15000); // Fetch data every 15000 milliseconds (15 seconds)

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [videoInferences.some(item => item.state !== InferenceState.COMPLETED)]); // Depend on videoInferences to update when it changes

    const fetchData = async(userId: string) => {
        try {
            const response = await FetchService.getVideoByUserId(userId);
            setActiveBackend(true);
            if (response && response.status===200) {
                const data = response.data;
                if (data instanceof Array) {
                    setActiveBackendMessage('');
                    const sortedData = data.sort((a, b) => a.time_created - b.time_created); // sorted in descending order
                    const formattedData: ProcessedVideoStateData[] = sortedData.map(eachCellData => {
                        return {...eachCellData, name: getFileNameFromUrl(eachCellData.urls.video), time_created: formatEpochTimestampDate(eachCellData.time_created!),
                            size: formatBytes(eachCellData.size), state: capitalize(eachCellData.state)};
                    });
                    setVideoInferences(formattedData);
                    setIsLoading(false);
                } else {
                    setVideoInferences([]);
                }
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response && (error.response.status === 401 || error.response.status === 500) || error.code === 'ERR_NETWORK') {
                    setActiveBackend(false);
                    if (error.response) setActiveBackendMessage(capitalize(error.response.statusText?.toLowerCase()));
                }
            }
            setIsLoading(false);
        }
    };

    const handleDeleteInferenceVideo = async (processedVideoData: ProcessedVideoStateData) => {
        try {
            const requestBody = {video: {url: processedVideoData.urls.video}};
            const response = await FetchService.deleteVideo(requestBody);
            if (response && response.status === 200) {
                notifySuccess("Video has been deleted successfully.");
                await fetchData(authUser?.uid);
            } else {
                console.error('Response is not a Blob:', response);
                notifyError("Video deletion failed. Please try again.");
            }
        } catch (error) {
            console.error("Error delete file:", error);
            console.error(error);
            notifyError("An unexpected error occurred during the video deletion.");
        }
        toggleDeleteInferenceVideoModal();
    };


    const handleDeleteAllInferenceVideo = async (inferences: ProcessedVideoStateData[]) => {
        try {
            let isDeleted = false;
            for (let i = 0; i < inferences.length; i++) {
                const eachInference = inferences[i];
                const requestBody = {video: {url: eachInference.urls.video}};
                const response = await FetchService.deleteVideo(requestBody);
                if (response && response.status === 200) {
                    isDeleted = true;
                }
            }
            if (isDeleted) notifySuccess("All video inferences have been deleted successfully.");
        } catch (error) {
            console.error("Error delete file:", error);
            console.error(error);
            notifyError("An unexpected error occurred during the video deletion.");
        }
        toggleDeleteAllInferenceVideoModal();
        await fetchData(authUser?.uid);
    };

    const handleDeleteInferenceVideoModal = async (processedVideo: ProcessedVideoStateData) => {
        setSelectedProcessedInference(processedVideo);
        toggleDeleteInferenceVideoModal();
    };

    const toggleDeleteInferenceVideoModal = () => {
        setDeleteInferenceVideoModal(!deleteInferenceVideoModal);
        document.body.classList.add("no_padding");
    }

    const toggleDeleteAllInferenceVideoModal = () => {
        setDeleteAllInferenceVideoModal(!deleteAllInferenceVideoModal);
        document.body.classList.add("no_padding");
    }

    const handleToggleModal = () => setIsModalOpen(!isModalOpen);

    const handleDownloadAll = async () => {
        await fetchData(authUser?.uid);
        await downloadAllProcessedVideoData(videoInferences, authUser)
    }

    const handleDeleteAll = async () => {
        toggleDeleteAllInferenceVideoModal();
        await fetchData(authUser?.uid);
    }

    const handleVideoUpload = async (files: File[], country: CountryProps, categories: CategoryProps[]) => {
        const inferenceParams: { [key: string]: any } = {};
        // Add Category value as well
        // inferenceParams['country'] = country?.value!;
        // inferenceParams['categories'] = categories.map(category => category.value);
        // TODO need to remove priority from next release
        // inferenceParams['priority'] = 3;
        if (!DROPZONE_MULTIPLE_VIDEO_FILES) {
            try {
                // Update Status
                const formData = new FormData();
                formData.append("user", JSON.stringify({id: authUser?.uid}));
                formData.append("inference", JSON.stringify(inferenceParams));
                formData.append(files[0].name, files[0]);
                // setProcessFileStatus(InferenceState.UPLOADING);

                const response = await FetchService.uploadVideo(formData, {
                    headers: {
                        'Content-Type': headerContentType.FORM_DATA_TYPE,
                        'X-API-KEY': getUserXApiKey()
                    },
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        setUploadPercentage(Math.round((progressEvent.loaded / progressEvent.total!) * 100));
                        setProcessFileStatus(InferenceState.UPLOADING);
                    },
                });

                if (response && response.status === 200) {
                    setProcessFileStatus(InferenceState.PROCESSING);
                    await fetchData(authUser?.uid);
                    handleToggleModal();
                    notifySuccess("Video has been uploaded successfully.");
                } else {
                    console.error('Response is not a Blob:', response);
                    notifyError("Video upload failed. Please try again.");
                }
            } catch (error) {
                console.error("Error uploading files:", error);
                notifyError("An unexpected error occurred during the video upload.");
            }
        }
    };

    const toggleActiveTab = (tab: ActiveTabType) => {
        if (activeTab !== tab) setActiveTab(tab);
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={{label: APP_CONFIG_DEFAULT.title, path: APP_CONFIG_DEFAULT.path}}
                                 breadcrumbItem={{label: APP_INFERENCE_PAGE.label, path: APP_INFERENCE_PAGE.path}}/>
                    <Row>
                        <Col lg={12} xl={12}>
                            <Card>
                                <CardBody>
                                    <VideoInferenceDataTableActionMenu
                                        activeBackend={activeBackend}
                                        modalOpen={isModalOpen}
                                        onToggleModal={handleToggleModal}
                                        progress={uploadPercentage}
                                        videoInferences={videoInferences}
                                        onDownloadAll = {handleDownloadAll}
                                        onDeleteAll = {handleDeleteAll}
                                        onUpdateUploadPercentage={setUploadPercentage}
                                        status={processFileStatus}
                                        onUpdateProcessFileStatus={setProcessFileStatus}
                                        onHandleUploadVideo={handleVideoUpload}
                                    />
                                    <Row>
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === ActiveTabType.ALL})}
                                                    onClick={() => toggleActiveTab(ActiveTabType.ALL)}>{capitalize(ActiveTabType.ALL)}</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === ActiveTabType.PENDING})}
                                                    onClick={() => toggleActiveTab(ActiveTabType.PENDING)}>{capitalize(ActiveTabType.PENDING)}</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === ActiveTabType.PROCESSING})}
                                                    onClick={() => toggleActiveTab(ActiveTabType.PROCESSING)}>{capitalize(ActiveTabType.PROCESSING)}</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === ActiveTabType.COMPLETED})}
                                                    onClick={() => toggleActiveTab(ActiveTabType.COMPLETED)}>{capitalize(ActiveTabType.COMPLETED)}</NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={ActiveTabType.ALL}>
                                                <Row>
                                                    <InferenceDataTable
                                                        isDataLoading={isLoading}
                                                        activeBackend={activeBackend}
                                                        inferences={videoInferences}
                                                        onDeleteInferenceVideo={handleDeleteInferenceVideoModal}
                                                    />
                                                </Row>
                                            </TabPane>
                                        </TabContent>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={ActiveTabType.PENDING}>
                                                <Row>
                                                    <InferenceDataTable
                                                        isDataLoading={isLoading}
                                                        activeBackend={activeBackend}
                                                        inferences={videoInferences.filter(item => item.state.toLowerCase() === InferenceState.PENDING)}
                                                        onDeleteInferenceVideo={handleDeleteInferenceVideoModal}
                                                    />
                                                </Row>
                                            </TabPane>
                                        </TabContent>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={ActiveTabType.PROCESSING}>
                                                <Row>
                                                    <InferenceDataTable
                                                        isDataLoading={isLoading}
                                                        activeBackend={activeBackend}
                                                        inferences={videoInferences.filter(item => item.state.toLowerCase() === InferenceState.PROCESSING)}
                                                        onDeleteInferenceVideo={handleDeleteInferenceVideoModal}
                                                    />
                                                </Row>
                                            </TabPane>
                                        </TabContent>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={ActiveTabType.COMPLETED}>
                                                <Row>
                                                    <InferenceDataTable
                                                        isDataLoading={isLoading}
                                                        activeBackend={activeBackend}
                                                        inferences={videoInferences.filter(item => item.state.toLowerCase() === InferenceState.COMPLETED)}
                                                        onDeleteInferenceVideo={handleDeleteInferenceVideoModal}
                                                    />
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <DeleteModal
                isModalOpen={deleteInferenceVideoModal}
                title="Delete Video Inference"
                bodyMessage="this"
                deleteActionLabel="Delete"
                toggleModal={toggleDeleteInferenceVideoModal}
                deleteData={selectedProcessedInference!}
                handleDeleteAction={handleDeleteInferenceVideo}
            />

            <DeleteModal isModalOpen={deleteAllInferenceVideoModal}
                         title="Delete all video inferences"
                         bodyMessage="all inferences"
                         deleteActionLabel="Delete"
                         toggleModal={toggleDeleteAllInferenceVideoModal}
                         deleteData={videoInferences}
                         handleDeleteAction={handleDeleteAllInferenceVideo}
            />
        </React.Fragment>
    );
};

export default withRouter(Inference);