import React, {ReactNode} from "react";
import {Navigate} from "react-router-dom";
import {AUTH_LOGIN_PAGE, AUTH_LOGOUT_PAGE} from "../config/config";
import AuthUserService from "../services/AuthUserService";
import {RoleType, UserStatus} from "../constants/enums/Auth";
import {AuthUserData} from "../constants/models/Models";
import {notifyError} from "../services/NotificationService";

interface AuthMiddlewareTestProps {
    children: ReactNode;
    roles: RoleType[];
}

const ProtectedRouteMiddleware = ({children, roles}: AuthMiddlewareTestProps) => {
    const loggedUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
    if (!loggedUser) {
        return <Navigate to={{pathname: AUTH_LOGIN_PAGE.path, state: { from: location }}} />
    }
    // Check the user is suspended or not
    const suspendedUser= loggedUser.status?.toLowerCase() === UserStatus.SUSPENDED.toLowerCase();
    if (suspendedUser) {
        notifyError("Your account has been suspended!. Please contact support for more information.")
        return <Navigate to={{pathname: AUTH_LOGOUT_PAGE.path, state: { from: location }}} />
    }

    // Check the user roles
    const userRoles: RoleType[] = loggedUser.roles;

    // Handle the routing based on user roles and pages authorization
    const hasAccess = roles!.some(role => userRoles.includes(role));
    if (!hasAccess) {
        // If authenticated but not authorized, redirect to access denied page or login page
        return <Navigate to={{pathname: AUTH_LOGIN_PAGE.path, state: { from: location }}} />
    }
    return <React.Fragment>{children}</React.Fragment>;
};

export default ProtectedRouteMiddleware;
