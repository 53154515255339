import React from "react";

import {Route} from "react-router-dom";

// Import Routes all
import {
    AUTH_ROUTES,
    USER_ROUTES
} from "./routes/allRoutes";
import {APPLICATION_AUTH_CONFIG} from "./config/auth_config";

// layouts Format
import NonAuthLayout from "./common/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import {initializeFirebaseServices} from "./utils/firebase_utils";

import Layout from "./common/Layout";
import {Routes} from "react-router";

import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import ProtectedRouteMiddleware from "./middleware/ProtectedRouteMiddleware";

//init firebase services
initializeFirebaseServices(APPLICATION_AUTH_CONFIG["firebase"]);

const App = () => {
    return (
        <React.Fragment>
            {/*Initialize the Toast container*/}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
            />

            <Routes>
                {/*
                Non-Authorized Routes
                */}
                <Route>
                    {AUTH_ROUTES.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <NonAuthLayout>
                                    {route.component}
                                </NonAuthLayout>
                            }
                            key={idx}
                        />
                    ))}
                </Route>

                {/*
                User only Authorized Routes
                */}
                <Route>
                    {USER_ROUTES.map((route, index) => (
                        <Route key={index} path={route.path} element= {<ProtectedRouteMiddleware roles={route.authorizedRoles!}><Layout>{route.component}</Layout></ProtectedRouteMiddleware>}/>
                    ))}
                </Route>

            </Routes>
        </React.Fragment>
    );
};

export default App;