import React, {useEffect, useMemo, useState} from "react";
import {Button, Row} from "reactstrap";
import {ReactDataTableColumnProps} from "../../constants/types";
import {AdminPanelUserAction, AuthUserData} from "../../constants/models/Models";
import {capitalize, getStatusBadgeCssClass} from "../../utils/utils";
import {UserStatus} from "../../constants/enums/Auth";
import AuthUserService from "../../services/AuthUserService";
import {ReactDataTableWithPagination} from "../Common/DataTable/ReactDataTableWithPagination";

interface AdminDataTableProps {
    isDataLoading: boolean;
    users: AuthUserData[];
    onViewUser:(user: AuthUserData) => void;
    onUpdateUser:(user: AuthUserData) => void;
    onHandleUserAction:(userAction: AdminPanelUserAction) => void;
}


const AdminDataTable = ({isDataLoading, users, onViewUser, onUpdateUser, onHandleUserAction}: AdminDataTableProps) => {
    const authUserService = AuthUserService;
    const [superAdmin, setSuperAdmin] = useState<boolean>(authUserService.isLoggedUserSuperAdmin());
    useEffect(() => {
        setSuperAdmin(authUserService.isLoggedUserSuperAdmin());
    });

    const disableActivateSuspendAction = (authUser: AuthUserData) => {
        if (authUserService.isLoggedUserSuperAdmin()) {
            if (authUserService.isUserSuperAdmin(authUser)) {
                return true;
            }
            return false;
        } else if (authUserService.isLoggedUserAdmin()) {
            if (authUserService.isUserSuperAdmin(authUser) || authUserService.isUserAdmin(authUser)) {
                return true;
            }
            return false;
        }
        return true;
    }

    const USER_DATA_COLUMNS: ReactDataTableColumnProps[] = useMemo(() =>
        [
            {
                Header: 'Email',
                Footer: 'Email',
                accessor: 'email',
                disableFilters: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return <div className="fw-medium processed-image" onClick={() => onViewUser(eachCellData)}>{eachCellData.email}</div>
                },
            },
            {
                Header: 'Name',
                Footer: 'Name',
                accessor: 'displayName',
                disableFilters: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return <div>{eachCellData.displayName}</div>
                },
            },
            {
                Header: 'Status',
                Footer: 'Status',
                accessor: 'status',
                disableFilters: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return <div className={`font-size-12 text-truncate ${getStatusBadgeCssClass(eachCellData.status)}`}>{capitalize(eachCellData.status)}</div>
                },
            },
            {
                Header: 'Created Date',
                Footer: 'Created Date',
                accessor: 'createdDate',
                disableFilters: false,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return <div>{eachCellData.createdDate}</div>
                },
            },
            {
                Header: 'Modified Date',
                Footer: 'Modified Date',
                accessor: 'modifiedDate',
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return <div>{eachCellData.modifiedDate}</div>
                },
            },
            {
                Header: 'Last Login',
                Footer: 'Last Login',
                accessor: 'lastLoginAt',
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return <div>{eachCellData.lastLoginAt}</div>
                },
            },
            {
                Header: 'Action',
                filterable: false,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return (
                        <div className="d-flex flex-wrap gap-1">
                            <Button color="btn btn-info-subtle" onClick={() => onViewUser(eachCellData)}>
                                <i className="uil uil-eye me-2"></i>View
                            </Button>
                            {!disableActivateSuspendAction(eachCellData) &&
                                <Button color="btn btn-secondary-subtle" onClick={() => onUpdateUser(eachCellData)}>
                                    <i className="uil uil-edit-alt me-2"></i>Edit
                                </Button>
                            }
                            {!disableActivateSuspendAction(eachCellData) &&
                                <Button className="btn btn-success-subtle" disabled={disableActivateSuspendAction(eachCellData) || eachCellData.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase()} onClick={() => onHandleUserAction({action: UserStatus.ACTIVE, authUser: eachCellData})}>
                                    <i className="uil uil-check-circle me-2"></i>Activate
                                </Button>
                            }
                            {!disableActivateSuspendAction(eachCellData) &&
                                <Button color="btn btn-danger-subtle" disabled={disableActivateSuspendAction(eachCellData) || eachCellData.status?.toLowerCase() === UserStatus.SUSPENDED.toLowerCase()|| eachCellData.status?.toLowerCase() !== UserStatus.ACTIVE.toLowerCase()} onClick={() => onHandleUserAction({action: UserStatus.SUSPENDED, authUser: eachCellData})}>
                                    <i className="uil uil-ban me-2"></i>Suspend
                                </Button>
                            }
                        </div>
                    );
                },
            }
        ], []);

    return (
        <React.Fragment>
            <Row>
                <ReactDataTableWithPagination columns={USER_DATA_COLUMNS} data={users} activeBackend={true} isDataLoading={isDataLoading}/>
            </Row>
        </React.Fragment>
    );
};

export default AdminDataTable;