import { combineReducers } from 'redux'

import layoutReducer from "./Layout/reducer"

//register
import registerReducer from "./Auth/Register/reducer";

//login
import loginReducer from "./Auth/Login/reducer";

// // User Profile 
import profileReducer from "./Auth/Profile/reducer";

// // Forget Password
import forgetPasswordReducer from "./Auth/Forgetpwd/reducer";

const rootReducer = combineReducers({
    Layout: layoutReducer,
    register: registerReducer,
    login: loginReducer,
    forgetPassword: forgetPasswordReducer,
    profile: profileReducer,
})

export default rootReducer