import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer"

//Include Both Helper File with needed methods
import {getFirebaseAuthService} from "../../../utils/firebase_utils";


export const userForgetPassword: any = (user: any) => async (dispatch: any) => {

    try {
        let response;
        const firebaseAuthService = getFirebaseAuthService();
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            response = firebaseAuthService!.forgotUserPassword(user.email);
        }
        const data = await response;
        if (data) {
            dispatch(userForgetPasswordSuccess("Reset link are sent to your mailbox, check there first"))
        }
    } catch (forgetError) {
        dispatch(userForgetPasswordError(forgetError))
    }
}