import React from "react";
import {Container, Row, Col, Card, CardBody, Label} from "reactstrap";

// withRouter
import withRouter from "../../common/withRouter";
//Import Breadcrumb
import Breadcrumb from "../../common/Breadcrumb";

import avatar from "../../assets/images/users/avatar.png";
// actions
import { APP_CONFIG_DEFAULT, SETTINGS_MENU_USER_PROFILE } from "../../config/config";
import {AuthUserData} from "../../constants/models/Models";
import AuthUserService from "../../services/AuthUserService";

const UserProfile = () => {
    document.title = SETTINGS_MENU_USER_PROFILE.label + " | " + APP_CONFIG_DEFAULT.title;

    const userData: AuthUserData = AuthUserService.getLoggedAuthorizedUser();

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title={{label: APP_CONFIG_DEFAULT.title,  path: APP_CONFIG_DEFAULT.path}}
                                breadcrumbItem={{label:SETTINGS_MENU_USER_PROFILE.label, path:SETTINGS_MENU_USER_PROFILE.path}} />
                    <Row>
                        <Col xl={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="ms-3">
                                            <img src={userData?.photoURL || avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                        </div>

                                        <div className="flex-grow-1 align-self-center ms-3">
                                            <div className="text-muted">
                                                <h5>{userData?.displayName}</h5>
                                                <p className="mb-1">{userData?.email?userData?.email:''}</p>
                                                <p className="mb-0">Id no: {userData?.uid?userData?.uid:''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <h4 className="card-title mb-4">User Details</h4>
                    <Card>
                        <CardBody>
                            <div className="card border shadow-none mb-5">
                                <div className="card-header d-flex align-items-center">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm">
                                            <div
                                                className="avatar-title rounded-circle btn-primary-subtle text-primary">
                                                01
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="card-title">General Info</h5>
                                    </div>
                                </div>
                                <CardBody>

                                    <Row>
                                        <div className="mb-3">
                                            <Label className="text-muted mb-1">Name {" "}:</Label>
                                            <h5 className="font-size-14 text-truncate">{userData?.displayName}</h5>
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className="mb-3">
                                            <Label className="text-muted mb-1">Position {" "}:</Label>
                                            <h5 className="font-size-14 text-truncate">{userData?.designation}</h5>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="text-muted mb-1">Description {" "}:</Label>
                                            <h5 className="font-size-14 text-truncate">{userData?.description}</h5>
                                        </div>
                                    </Row>
                                </CardBody>
                            </div>
                            <div className="card border shadow-none mb-5">
                                <div className="card-header d-flex align-items-center">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm">
                                            <div
                                                className="avatar-title rounded-circle btn-primary-subtle text-primary">
                                                02
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="card-title">Contact Info</h5>
                                    </div>
                                </div>
                                <CardBody>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Email {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.email}</h5>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Phone Number {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.phoneNumber}</h5>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Address 1 {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.address1}</h5>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Address 2 {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.address2}</h5>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <div className="mb-3">
                                            <Label className="text-muted mb-1">City {" "}:</Label>
                                            <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.city}</h5>
                                        </div>
                                    </Row>

                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">State {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.state}</h5>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="text-muted mb-1">Post Code {" "}:</Label>
                                                <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.postCode}</h5>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                        <div className="mb-3">
                                            <Label className="text-muted mb-1">Country {" "}:</Label>
                                            <h5 className="font-size-14 text-truncate">{userData?.addressInfo?.country.name}</h5>
                                        </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(UserProfile);