import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {
    CategoryProps,
    CountryProps, ImageFileData,
    ProcessedVideoStateData,
    ScannerParameters,
    ScannerParametersTest
} from "../../../constants/types";
import Select from "react-select";
import {
    APP_INFERENCE_PAGE,
    COUNTRY_CATEGORIES_OPTIONS,
    COUNTRY_OPTIONS
} from "../../../config/config";
import {Link} from "react-router-dom";

interface VideoAnalyserDataTableMenuProps {
    videoInference: ProcessedVideoStateData;
    onHandleDownloadVideo: (videoInference: ProcessedVideoStateData) => Promise<void>;
    onHandleExportCSV: (videoInference: ProcessedVideoStateData) => Promise<void>;
    onHandleDownloadJSON: (videoInference: ProcessedVideoStateData) => Promise<void>;
    openInferenceDataTable?: (openInferenceDataTable: boolean) => void;
    parameters?: ScannerParameters;
    onUpdateScannerParameters: (params: (prevParams: ScannerParameters) => ScannerParameters) => void;
}

const VideoAnalyserDataTableActionMenu = ({videoInference, onHandleDownloadVideo, onHandleExportCSV, onHandleDownloadJSON, parameters, onUpdateScannerParameters}: VideoAnalyserDataTableMenuProps) => {
    const [selectedCountry, setSelectedCountry] = useState<CountryProps>();
    const [userCategories, setUserCategories] = useState<CategoryProps[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<CategoryProps[]>([]);

    const handleSelectCategories = (categories: CategoryProps[]) => {
        setSelectedCategories(categories);
        onUpdateScannerParameters(params => ({...params, category: categories}));
    };

    const handleSelectCountry = (country: CountryProps) => {
        const categories: CategoryProps[] = COUNTRY_CATEGORIES_OPTIONS[country.value];
        onUpdateScannerParameters(params => ({...params, country: country}));
        setUserCategories(categories);
        if (country.value != parameters?.country?.value) {
            const alreadySelectedCategories: CategoryProps[] = categories.filter(currentCategory => selectedCategories.some(selectedCategory => selectedCategory.value === currentCategory.value));
            setSelectedCategories(alreadySelectedCategories);
            onUpdateScannerParameters(params => ({...params, category: alreadySelectedCategories}));
        }
    };


    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col lg={6} md={6}>
                    <Row>
                        <Col lg={3} md={6}>
                            <Select
                                value={selectedCountry}
                                options={COUNTRY_OPTIONS}
                                onChange={handleSelectCountry}
                                placeholder="Country..."
                                classNamePrefix="select"
                            />
                        </Col>
                        <Col lg={6} md={12}>
                            <Select
                                // defaultValue={[CATEGORY_OPTIONS[0]]}
                                value={selectedCategories}
                                // options={CATEGORY_OPTIONS}
                                options={userCategories}
                                isMulti
                                className="basic-multi-select"
                                onChange={handleSelectCategories}
                                placeholder="Category..."
                                classNamePrefix="select"
                            />
                        </Col>
                    </Row>
                </Col>

                <Col lg={6} md={6}>
                    <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <Button color="btn btn-btn btn-secondary-subtle" onClick={() => onHandleDownloadVideo(videoInference)}>
                            Download
                        </Button>

                        <Button color="btn btn-btn btn-secondary-subtle" onClick={() => onHandleDownloadJSON(videoInference)}>
                            JSON
                        </Button>

                        <Button color="btn btn-btn btn-secondary-subtle" onClick={() => onHandleExportCSV(videoInference)}>
                            Export CSV
                        </Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default VideoAnalyserDataTableActionMenu;