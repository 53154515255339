import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {
    DROPZONE_IMAGE_FORMATS,
    DROPZONE_MAX_IMAGE_FILES,
    DROPZONE_MAX_VIDEO_FILES,
    DROPZONE_MAX_VIDEO_MB_SIZE,
    DROPZONE_MULTIPLE_IMAGE_FILES,
    DROPZONE_MULTIPLE_VIDEO_FILES,
    DROPZONE_VIDEO_FORMATS
} from "../../../constants/constants";
import Dropzone from "react-dropzone";
import {CategoryProps, CountryProps} from "../../../constants/types";
import {COUNTRY_CATEGORIES_OPTIONS, COUNTRY_OPTIONS} from "../../../config/config";
import {InferenceType} from "../../../constants/enums/all_enums";

interface FileUploadModalProps {
    isOpen: boolean;
    fileUploadType: InferenceType;
    toggleModal: () => void;
    handleUpload: (files: File[], country: CountryProps, categories: CategoryProps[]) => void;
    title: string;
}

const FileUploadModal = ({isOpen, fileUploadType, toggleModal, handleUpload, title}: FileUploadModalProps) => {
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<CountryProps>();
    const [userCategories, setUserCategories] = useState<CategoryProps[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<CategoryProps[]>([]);
    const maxFileSize = DROPZONE_MAX_VIDEO_MB_SIZE * 1024 * 1024; // MB in bytes

    const handleSelectCategories = (categories: CategoryProps[]) => {
        setSelectedCategories(categories);
    };

    useEffect(() => {
        setSelectedFiles([]);
    },[isOpen])

    const handleSelectCountry = (country: CountryProps) => {
        const currentCategories: CategoryProps[] = COUNTRY_CATEGORIES_OPTIONS[country.value];
        setSelectedCountry(country);
        setUserCategories(currentCategories);
        if (country.value != selectedCountry?.value) {
            const alreadySelectedCategories: CategoryProps[] = currentCategories.filter(currentCategory => selectedCategories.some(selectedCategory => selectedCategory.value === currentCategory.value));
            setSelectedCategories(alreadySelectedCategories);
        }
    };


    const handleAcceptedFiles = (acceptedFiles: File[]) => {
        setSelectedFiles(acceptedFiles);
        // setProcessFileStatus(InferenceState.SELECTED)
    }

    const handleFileUpload = async () => {
        if (!selectedFiles || selectedFiles.length === 0) return;
        if (fileUploadType === InferenceType.VIDEO) {
            //TODO Check if file size is within limit (1GB) Convert MB to bytes
            if (selectedFiles[0].size >= maxFileSize) return;
        }
        // if (!selectedCountry) return;
        // if (selectedCategories.length === 0 || !selectedCategories) return;

        handleUpload(selectedFiles, selectedCountry!, selectedCategories);
        setSelectedFiles([]);
    }

    const toggleAndClear = () => {
        toggleModal();
        setSelectedFiles([]);
    }


    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggleAndClear} backdrop={"static"}>
                <ModalHeader toggle={toggleAndClear} tag="h5">{title}</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Dropzone
                                maxFiles={fileUploadType === InferenceType.VIDEO ? DROPZONE_MAX_VIDEO_FILES : DROPZONE_MAX_IMAGE_FILES}
                                multiple={fileUploadType === InferenceType.VIDEO ? DROPZONE_MULTIPLE_VIDEO_FILES : DROPZONE_MULTIPLE_IMAGE_FILES}
                                accept={fileUploadType === InferenceType.VIDEO ? DROPZONE_VIDEO_FORMATS : DROPZONE_IMAGE_FORMATS}
                                onDrop={acceptedFiles => {
                                    handleAcceptedFiles(acceptedFiles);
                                }}>
                                {({getRootProps, getInputProps}) => (
                                    <div className="dropzone-modal">
                                        <div className="dz-message needsclick" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <i className="icon-display-4 text-muted fas fa-cloud-upload-alt"></i>
                                            </div>
                                            {selectedFiles.length > 0 &&
                                                <h5>You have selected <strong>{selectedFiles.length}</strong> {fileUploadType === InferenceType.VIDEO ? selectedFiles.length===1?'video':'videos' : selectedFiles.length===1?'image':'images'}</h5>
                                            }
                                            {selectedFiles.length === 0 &&
                                                <h5>Drag & Drop your {fileUploadType === InferenceType.VIDEO ? "video" : "image"} file here or Click to browse</h5>
                                            }
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </Row>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <div className="text-end mt-2">
                                <div className="d-flex justify-content-end flex-wrap gap-2 mb-3">
                                    <Button color="btn btn-secondary-subtle" onClick={toggleAndClear}>
                                        Cancel
                                    </Button>
                                    <Button color="btn btn-primary-subtle" onClick={handleFileUpload}
                                            disabled={selectedFiles.length === 0 ||
                                                // selectedCategories.length === 0 ||
                                                selectedFiles.reduce((totalSize: number, file: File) => totalSize + file.size, 0) > maxFileSize
                                    }>
                                        Upload
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default FileUploadModal;