import React, {useEffect, useState} from "react";

import {Row, Col, Alert, Container, Input, Label, Form, FormFeedback} from "reactstrap";

//redux
import {useSelector, useDispatch} from "react-redux";

import {Link, Navigate, useNavigate} from "react-router-dom";

import PropTypes from "prop-types";

// withRouter
import withRouter from "../../common/withRouter";
// actions
import {loginUser} from "../../slices/thunks";

//import images
import logoDark from "../../assets/images/scanner-logo.png";
import {useFormik} from "formik";
import * as Yup from "yup";
import {createSelector} from "reselect";
import {
    APP_CONFIG_DEFAULT,
    AUTH_FORGOT_PASSWORD_PAGE,
    AUTH_LOGIN_PAGE,
    AUTH_SIGNUP_PAGE
} from "../../config/config";
import {
    DEAKIN_NAME,
    DEAKIN_URL,
    IHT_DEAKIN_NAME,
    IHT_DEAKIN_URL,
    IISRI_DEAKIN_NAME,
    IISRI_DEAKIN_URL
} from "../../utils/url_helper";
import {AuthUserData} from "../../constants/models/Models";
import AuthUserService from "../../services/AuthUserService";
import {UserStatus} from "../../constants/enums/Auth";


interface LoginProps {
    props: any;
}

const Login = ({props}: LoginProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [error, setError] = useState<{code: string, message: string}>({code:'', message:''});
    // const authUser: string | null = localStorage.getItem("authUser");
    const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
    const pendingUser= authUser && authUser.status?.toLowerCase() === UserStatus.PENDING.toLowerCase();

    document.title = AUTH_LOGIN_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;

    const selectSignInProperties = createSelector(
        (state: any) => state.login,
        (layout) => ({
            user: layout.user,
            responseError: layout.error,
        })
    );
    // Inside your component
    const {responseError, user} = useSelector(selectSignInProperties);

    useEffect(() => {
        if (responseError && responseError.code){
            setError(responseError);
        }
        if (user) {
            setError({code:'', message:''});
        }
        if (authUser) {
            // Redirect to default page if user is already logged in
            // Check the user is suspended or not
            // if (pendingUser) {
            //     navigate(APP_DASHBOARD_PAGE.path);
            // }
            navigate(APP_CONFIG_DEFAULT.path);
        }

    }, [dispatch, authUser, user, responseError]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().trim().required("Please enter your email"),
            password: Yup.string().trim().required("Please enter your password"),
        }),
        onSubmit: values => {
            dispatch(loginUser(values, navigate));
        },
    });

    return authUser ? (
        // Redirect to default page if user is already logged in
        <Navigate to={{ pathname: APP_CONFIG_DEFAULT.path }} />
    ) : (
    <React.Fragment>
        <div className="authentication-bg min-vh-100">
            <div className="bg-overlay bg-white"></div>
            <Container>
                <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                    <Row className="justify-content-center my-auto">
                        <Col md={8} lg={6} xl={4}>
                            <div className="text-center py-5">
                                <div className="mb-4 mb-md-5">
                                    <Link to={APP_CONFIG_DEFAULT.path}
                                          className="d-block auth-logo">
                                        <img
                                            src={logoDark}
                                            alt=""
                                            height="22"
                                            className="auth-logo-dark"
                                        />
                                    </Link>
                                </div>
                                <div className="mb-4">
                                    <p>Sign in to continue to {APP_CONFIG_DEFAULT.title}.</p>
                                </div>
                                <Form onSubmit={e => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    {error && error.code && error.message && (
                                        <Alert color="danger">{error.message}</Alert>
                                    )}
                                    <div className="form-floating form-floating-custom text-start mb-3">
                                        <Input
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            id="input-username"
                                            placeholder="Enter User Name"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.email || ""}
                                            invalid={
                                                validation.touched.email && validation.errors.email
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.email && validation.errors.email ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.email}
                                            </FormFeedback>
                                        ) : null}
                                        <Label htmlFor="input-username">Email</Label>
                                        <div className="form-floating-icon">
                                            <i className="uil uil-users-alt"></i>
                                        </div>
                                    </div>
                                    <div className="form-floating form-floating-custom text-start mb-3">
                                        <Input
                                            name="password"
                                            type="password"
                                            className="form-control"
                                            id="input-password"
                                            placeholder="Enter Password"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.password || ""}
                                            invalid={
                                                validation.touched.password &&
                                                validation.errors.password
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.password &&
                                        validation.errors.password ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.password}
                                            </FormFeedback>
                                        ) : null}
                                        <Label htmlFor="input-password">Password</Label>
                                        <div className="form-floating-icon">
                                            <i className="uil uil-padlock"></i>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <button className="btn btn-info w-100" type="submit">
                                            Log In
                                        </button>
                                    </div>

                                    <div className="mt-4">
                                        <Link to={AUTH_FORGOT_PASSWORD_PAGE.path}
                                              className="fw-medium text-decoration-underline">
                                            {" "}
                                            Forgot your password?{" "}
                                        </Link>
                                    </div>
                                </Form>

                                <div className="mt-5 text-center text-muted">
                                    <p>
                                        Don&apos;t have an account ?{" "}
                                        <Link to={AUTH_SIGNUP_PAGE.path}
                                              className="fw-medium text-decoration-underline">
                                            {" "}
                                            {AUTH_SIGNUP_PAGE.label}{" "}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <div className="text-center text-muted p-4">
                                <p className="mb-0">
                                    &copy; {new Date().getFullYear()} {APP_CONFIG_DEFAULT.title}.
                                    Developed by <Link to={IISRI_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IISRI_DEAKIN_NAME}</Link>
                                    {" "} and <Link to={IHT_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IHT_DEAKIN_NAME}</Link>,
                                    {" "}<Link to={DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{DEAKIN_NAME}</Link>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    </React.Fragment>
    );
};

export default withRouter(Login);

Login.propTypes = {
    history: PropTypes.object,
};