import React, {useMemo} from "react";
import {useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useExpanded} from 'react-table';
import {Table} from "reactstrap";
import {DefaultColumnFilter} from "./ColumnFilter";
import DynamicPagination from "./DynamicPagination";

interface ReactDataTablePaginationProps {
    columns: any;
    data: any;
    activeBackend?: boolean;
    isDataLoading?: boolean;
    isPendingUser?: boolean;
    customPageSize?: number;
}

export const ReactDataTableWithPagination = ({columns, data, activeBackend, isDataLoading, isPendingUser, customPageSize=10}: ReactDataTablePaginationProps) => {
    const defaultColumns = useMemo(() => {
        return {Filter: DefaultColumnFilter}
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        pageCount,
        gotoPage,
        setPageSize,
        state,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: columns,
            data: data,
            defaultColumn: defaultColumns,
            initialState: {
                pageIndex: 0,
                pageSize: customPageSize, // set custom page size form here
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            } // Pass our hoisted table state
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

    const {globalFilter} = state;
    // setPageSize(10)
    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : " ";
    };

    return (
        <React.Fragment>
            {/*for global search*/}
            {/*<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />*/}

            {isDataLoading ? (
                <p className="table-data-text-align">
                    Loading...
                </p>
            ) : (
                <div className="table-responsive react-table">
                    <Table className="custom-header-css table align-middle table-hover" {...getTableProps()}>
                        <thead className="table-light table-nowrap">
                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                {headerGroup.headers.map((column: any) => (
                                    <th key={column.id} {...column.getHeaderProps()}>
                                        <div className="mb-2" {...column.getSortByToggleProps()}>
                                            {column.render("Header")}
                                            <span>
                                            {generateSortingIndicator(column)}
                                        </span>
                                        </div>
                                        {column.canFilter ? column.render('Filter') : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {data.length > 0 ? (
                            page.map((row: any) => {
                                prepareRow(row);
                                return (
                                    <React.Fragment key={row.getRowProps().key}>
                                        <tr key={row.getRowProps().key} {...row.getRowProps()}>
                                            {row.cells.map((cell: any) => {
                                                // return <td key={cell.id} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                return <td
                                                    key={cell.id} {...cell.getCellProps([{style: cell.column.style}])}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                {activeBackend &&
                                    <td colSpan={columns.length} className="table-data-text-align">
                                        <div className="table-data-not-available">
                                            No data available!
                                        </div>
                                    </td>
                                }
                                {!activeBackend && !isPendingUser &&
                                    <td colSpan={columns.length} className="table-data-text-align">
                                        <div className="table-data-not-available">
                                            Service temporarily unavailable! Please try again later.
                                        </div>
                                    </td>
                                }
                                {isPendingUser && !activeBackend &&
                                    <td colSpan={columns.length} className="table-data-text-align">
                                        <div className="table-data-not-available">
                                            Your authorization request is pending review. Kindly try again later.
                                        </div>
                                    </td>
                                }
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
            )}

            {data && data.length > 0 &&
                <DynamicPagination
                    totalPages={pageCount}
                    currentPage={pageIndex + 1}
                    onPageChange={(page) => gotoPage(page - 1)} pageSize={pageSize}
                    totalEntries={data.length}
                />
            }
        </React.Fragment>
    );
}
