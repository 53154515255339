import {ApplicationAuthConfigProps} from "../constants/types";
import {FirebaseAuthErrorCode} from "../constants/enums/inference_enums";

export const APPLICATION_AUTH_CONFIG: ApplicationAuthConfigProps = {
    firebase : {
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_AUTHDOMAIN,
        databaseURL: process.env.REACT_APP_DATABASEURL,
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: process.env.REACT_APP_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENTID,
    },
    // google: {
    //     API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    //     CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //     SECRET: process.env.REACT_APP_GOOGLE_SECRET_KEY,
    // },
    // facebook: {
    //     APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
    // },
};


export const authErrorMessages = {
    [FirebaseAuthErrorCode.AUTH_USER_NOT_FOUND]: 'User not found. Please check your email address.',
    [FirebaseAuthErrorCode.AUTH_PERMISSION_DENIED]: 'Permission Denied: You don\'t have access to perform this action.',
    [FirebaseAuthErrorCode.AUTH_WRONG_PASSWORD]: 'Incorrect password. Please try again.',
    [FirebaseAuthErrorCode.AUTH_INVALID_EMAIL]: 'Invalid email address. Please enter a valid email.',
    [FirebaseAuthErrorCode.AUTH_EMAIL_ALREADY_IN_USE]: 'Email address is already in use',
    [FirebaseAuthErrorCode.AUTH_WEAK_PASSWORD]: 'Password is too weak. Please choose a stronger password.',
    [FirebaseAuthErrorCode.AUTH_INVALID_CREDENTIAL]: 'Invalid credential. Please try again with valid credential.',
    [FirebaseAuthErrorCode.AUTH_OPERATION_NOT_ALLOWED]: 'Authentication operation not allowed. Please contact support.',
    [FirebaseAuthErrorCode.AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL]: 'An account with this email already exists but with a different provider.',
    [FirebaseAuthErrorCode.AUTH_NETWORK_REQUEST_FAILED]: 'Network request failed. Please check your internet connection.',
};