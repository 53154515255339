import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Row, Col, Alert, Container, Form, Input, Label, Button} from "reactstrap";

// action
import {registerUser} from "../../slices/thunks";

//redux
import {useSelector, useDispatch} from "react-redux";

import {Link} from "react-router-dom";

// import images
import logoDark from "../../assets/images/scanner-logo.png";
import {createSelector} from "reselect";
import { APP_CONFIG_DEFAULT,
    AUTH_LOGIN_PAGE,
    AUTH_SIGNUP_PAGE
} from "../../config/config";
import {
    DEAKIN_NAME,
    DEAKIN_URL,
    IHT_DEAKIN_NAME,
    IHT_DEAKIN_URL,
    IISRI_DEAKIN_NAME,
    IISRI_DEAKIN_URL
} from "../../utils/url_helper";
import {isStrongPassword, isValidEmail} from "../../utils/utils";

const SignUp = () => {
    document.title = AUTH_SIGNUP_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;

    const [email, setEmail] = useState<string>('');
    const emailRef = useRef<HTMLInputElement>(null);
    const [password, setPassword] = useState<string>('');
    const passwordRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string>("");

    const allowedDomains = process.env!.REACT_APP_ALLOWED_EMAIL_DOMAINS!.split(',');

    const dispatch = useDispatch();

    const selectRegisterProperties = createSelector(
        (state: any) => state.register,
        (layout) => ({
            user: layout.user,
            registrationError: layout.registrationError,
            loading: layout.loading
        })
    );
    // Inside your component
    const {user} = useSelector(selectRegisterProperties);

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        if (error) setError("");
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if (error) setError("");
    };

    const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (email.trim() == "") {
            setError('Email is required.');
            emailRef.current?.focus();
            return;
        }

       /* const domain = email.split('@')[1];
        if (!allowedDomains.includes(domain)) {
            setError('You must sign up with an allowed organization email.');
            return;
        }*/

        if (!isValidEmail(email)) {
            setError('Please enter a valid email.');
            emailRef.current?.focus();
            return;
        }

        if (password.trim() == "") {
            setError('Password is required.');
            passwordRef.current?.focus();
            return;
        }

        if (!isStrongPassword(password)) {
            if (password.length < 6) {
                setError("Password must be at least six characters long.");
                passwordRef.current?.focus();
            } else {
                setError("Password must be at least six characters and should include a combination of numbers, letters and special character.");
                passwordRef.current?.focus();
            }
            return;
        }

        try {
            dispatch(registerUser({email, password}));
        } catch (error) {
            // error;
        }
    };

    useEffect(() => {
        // dispatch(apiError(""));
        if (user && !user.code) {
            // Clear form fields only when registration is successful
            setPassword("");
            setEmail("");
        }
    }, [dispatch, user]);

    return (
        <React.Fragment>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay bg-white"></div>
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={4}>
                                <div className="py-5">
                                    <div className="mb-4 mb-md-5">
                                        <Link to={APP_CONFIG_DEFAULT.path}
                                              className="d-block auth-logo text-center">
                                            <img src={logoDark} alt="" height="22" className="auth-logo-dark"/>
                                        </Link>
                                    </div>
                                    <div className="text-center mb-4">
                                        <h5 className="">Register Account</h5>
                                        <p>Get your {APP_CONFIG_DEFAULT.title} account now.</p>
                                    </div>
                                    <Form onSubmit={handleSignUp} className="needs-validation custom-form mt-4 pt-2">
                                        {user && user.code && user.message ? (
                                            <Alert color="danger">{user.message}</Alert>
                                        ) : user && !user.code && !user.message &&
                                            <Alert color="success">
                                                User has been registered successfully
                                            </Alert>
                                        }

                                        <div className="form-group">
                                            <Label htmlFor="email">Email</Label>
                                            <Input
                                                name="email"
                                                placeholder="Enter Email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                innerRef={emailRef}
                                                id="email"
                                                type="email"
                                                className="is-untouched is-pristine av-invalid form-control"
                                            />
                                        </div>

                                        <div className="form-group mt-3">
                                            <Label htmlFor="password">Password</Label>
                                            <Input
                                                name="password"
                                                placeholder="Enter Password"
                                                value={password}
                                                id="password"
                                                onChange={handlePasswordChange}
                                                nnerRef={passwordRef}
                                                type="password"
                                                className="is-untouched is-pristine av-invalid form-control"/>
                                        </div>

                                        {error && <div className="text-danger mb-3">{error}</div>}

                                        <div className="text-start">
                                            <p>By registering you agree to
                                                the {APP_CONFIG_DEFAULT.title + " "}
                                                <Link to="#" className="text-decoration-underline">Terms of Use</Link>
                                            </p>
                                        </div>

                                        <div className="mt-3">
                                            <Button className="btn btn-info w-100" type="submit">Register</Button>
                                        </div>
                                    </Form>

                                    <div className="mt-5 text-center">
                                        <p>Already have an account ?
                                            <Link to={AUTH_LOGIN_PAGE.path}
                                                  className="fw-medium text-decoration-underline">
                                                {" "}
                                                {AUTH_LOGIN_PAGE.label}{" "}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0">
                                        &copy; {new Date().getFullYear()} {APP_CONFIG_DEFAULT.title}.
                                        Developed by <Link to={IISRI_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IISRI_DEAKIN_NAME}</Link>
                                        {" "} and <Link to={IHT_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IHT_DEAKIN_NAME}</Link>,
                                        {" "}<Link to={DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{DEAKIN_NAME}</Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SignUp;
