import AuthUserService from "../services/AuthUserService";
import {UserStatus} from "../constants/enums/Auth";
import {AuthUserData} from "../constants/models/Models";

interface HeaderContentType {
  JSON_TYPE: string;
  URL_ENCODED_TYPE: string;
  FORM_DATA_TYPE: string;
  PLAIN_TEXT_TYPE: string;
  XML_TYPE: string;
  TEXT_HTML_TYPE: string;
}

export const headerContentType: HeaderContentType = {
  JSON_TYPE: 'application/json',
  URL_ENCODED_TYPE: 'application/x-www-form-urlencoded',
  FORM_DATA_TYPE: 'multipart/form-data',
  PLAIN_TEXT_TYPE: 'text/plain',
  XML_TYPE: 'application/xml',
  TEXT_HTML_TYPE: 'text/html'
}

export const getUserXApiKey = () => {
  const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
  if (authUser && authUser.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase()) {
    return AuthUserService.getLoggedAuthorizedUser()?.accessXApiKey
  } else {
    return '' // process.env.REACT_APP_X_API_KEY
  }
}
