import React from "react";
import {Button, Col, Row} from "reactstrap";
import {CategoryProps, CountryProps, ProcessedVideoStateData} from "../../constants/types";
import {InferenceType} from "../../constants/enums/all_enums";
import VideoFileUploadModal from "../Common/Modals/VideoFileUploadModal";
import {InferenceState} from "../../constants/enums/inference_enums";

interface VideoInferenceDataTableMenuProps {
    activeBackend: boolean;
    modalOpen: boolean;
    progress: number;
    status: any;
    onToggleModal: () => void;
    videoInferences: ProcessedVideoStateData[];
    onDownloadAll: () => Promise<void>;
    onDeleteAll: () => Promise<void>;
    onUpdateUploadPercentage: (percent: number) => void;
    onUpdateProcessFileStatus: (status: InferenceState) => void;
    onHandleUploadVideo: (files: File[], country: CountryProps, categories: CategoryProps[]) => void;
}

const VideoInferenceDataTableActionMenu = ({activeBackend, modalOpen, onToggleModal, onHandleUploadVideo, progress, videoInferences, onDownloadAll, onDeleteAll, onUpdateUploadPercentage, status, onUpdateProcessFileStatus}: VideoInferenceDataTableMenuProps) => {
    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col lg={1} md={1}>
                </Col>

                <Col lg={5} md={5}>
                </Col>

                <Col lg={6} md={6}>
                    <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2">
                        <Button onClick={onDownloadAll} color="btn btn-success-subtle" disabled={!activeBackend || !videoInferences?.some(fileData => fileData.state.toLowerCase().includes(InferenceState.COMPLETED))}>
                            Download All
                        </Button>
                        <Button onClick={onDeleteAll} color="btn btn-danger-subtle" disabled={videoInferences?.length == 0}>
                            Delete All
                        </Button>
                        <div className="text-sm-end">
                            <Button className="btn btn-primary-subtle" disabled={!activeBackend} onClick={onToggleModal}>Upload Video</Button>
                        </div>
                    </div>
                </Col>
                <VideoFileUploadModal isOpen={modalOpen} fileUploadType={InferenceType.VIDEO} toggleModal={onToggleModal} title="Upload Video" handleUpload={onHandleUploadVideo} uploadPercentage={progress} handleUpdateUploadPercentage={onUpdateUploadPercentage}
                                      status={status} handleUpdateProcessFileStatus={onUpdateProcessFileStatus}/>
            </Row>
        </React.Fragment>
    );
};

export default VideoInferenceDataTableActionMenu;