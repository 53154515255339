import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";
import {capitalize} from "../../../utils/utils";
import {UserStatus} from "../../../constants/enums/Auth";
import {AdminPanelUserAction} from "../../../constants/models/Models";

interface AdminPanelModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    userActionData: AdminPanelUserAction;
    handleAction: (userAction: AdminPanelUserAction) => void;
}

export const AdminPanelModal = ({isModalOpen, toggleModal, userActionData, handleAction}: AdminPanelModalProps) => {
    return (
        <React.Fragment>
            <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop={"static"}>
                <ModalHeader toggle={toggleModal} tag="h5">{userActionData?.action.toLowerCase() === UserStatus.SUSPENDED.toLowerCase()? capitalize("suspend") + " " + "user": userActionData?.action.toLowerCase() === UserStatus.ACTIVE.toLowerCase()? capitalize("activate") + " " + "user": capitalize(userActionData?.action) + " " + "user" }</ModalHeader>
                <ModalBody>
                    <p>
                        Are you sure you want to {" "}{userActionData?.action.toLowerCase() === UserStatus.SUSPENDED.toLowerCase()? "suspend".toLowerCase(): userActionData?.action.toLowerCase()} this user account?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="btn btn-light" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button color={`btn ${userActionData?.action.toLowerCase() === UserStatus.SUSPENDED.toLowerCase().toLowerCase()? "btn-danger":"btn-success"}`} onClick={() => handleAction(userActionData)}>
                        {userActionData?.action.toLowerCase() === UserStatus.SUSPENDED.toLowerCase().toLowerCase()? capitalize("suspend"):userActionData?.action.toLowerCase() === UserStatus.ACTIVE.toLowerCase().toLowerCase()? capitalize("activate") : capitalize(userActionData?.action)}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};