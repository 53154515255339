import { Navigate } from "react-router-dom";

//Inference
import ImageInference from "../pages/ImageInference";

//profile
import UserProfile from "../pages/Authentication/UserProfile"

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import SignUp from "../pages/Authentication/SignUp";
import {
  ADMIN_USER_EDIT_SUB_PAGE,
  ADMIN_USER_SUB_PAGE,
  APP_ADMIN_PAGE,
  APP_IMAGE_INFERENCE_PAGE,
  APP_INFERENCE_PAGE,
  AUTH_FORGOT_PASSWORD_PAGE,
  AUTH_LOGIN_PAGE,
  AUTH_LOGOUT_PAGE, AUTH_RESET_PASSWORD_PAGE,
  AUTH_SIGNUP_PAGE, INFERENCE_BRAND_SUB_MENU,
  SETTINGS_MENU_USER_EDIT,
  SETTINGS_MENU_USER_PROFILE
} from "../config/config";
import Inference from "../pages/Inference";
import React from "react";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import UserSetting from "../pages/User/UserSetting";
import AdminPanel from "../pages/Admin/AdminPanel";
import UserDetails from "../pages/Admin/UserDetails";
import UserEdit from "../pages/Admin/UserEdit";
import {RoleType} from "../constants/enums/Auth";
import BrandAnalyserDataTable from "../pages/Inference/Analyser/BrandAnalyserDataTable";

interface RouteProps {
  path: string;
  component: React.ReactNode;
  authorizedRoles?: RoleType[];
}

const ADMIN_SUPER_ADMIN_ROLES: RoleType[] = [RoleType.ADMIN_USER, RoleType.SUPER_ADMIN_USER];
const USER_ADMIN_SUPER_ROLES: RoleType[] = [RoleType.USER, RoleType.ADMIN_USER, RoleType.SUPER_ADMIN_USER];

// User only Authorized Routes
const USER_ROUTES: Array<RouteProps> = [
    // Image Inference
  { path: APP_IMAGE_INFERENCE_PAGE.path, component: <ImageInference/>, authorizedRoles: USER_ADMIN_SUPER_ROLES },

  // Video Inference
  { path: APP_INFERENCE_PAGE.path, component: <Inference/>, authorizedRoles: USER_ADMIN_SUPER_ROLES },
  { path: INFERENCE_BRAND_SUB_MENU.path, component: <BrandAnalyserDataTable/>, authorizedRoles: USER_ADMIN_SUPER_ROLES },
  // //profile
  { path: SETTINGS_MENU_USER_PROFILE.path, component: <UserProfile/>, authorizedRoles: USER_ADMIN_SUPER_ROLES },
  { path: SETTINGS_MENU_USER_EDIT.path, component: <UserSetting/>, authorizedRoles: USER_ADMIN_SUPER_ROLES },

  // Admin/Super Admin Panel
  { path: APP_ADMIN_PAGE.path, component: <AdminPanel/> , authorizedRoles: ADMIN_SUPER_ADMIN_ROLES},
  { path: ADMIN_USER_SUB_PAGE.path, component: <UserDetails/>, authorizedRoles: ADMIN_SUPER_ADMIN_ROLES },
  { path: ADMIN_USER_EDIT_SUB_PAGE.path, component: <UserEdit/>, authorizedRoles: ADMIN_SUPER_ADMIN_ROLES },

  // Redirects the routing
  { path: "/", component: <Navigate to={APP_INFERENCE_PAGE.path} />, authorizedRoles: USER_ADMIN_SUPER_ROLES },
  { path: "*", component: <Navigate to={APP_INFERENCE_PAGE.path} />, authorizedRoles: USER_ADMIN_SUPER_ROLES },
];

// User only Authorized Routes

// Non-Authorized Routes
const AUTH_ROUTES: Array<RouteProps> = [
  //Authentication pages
  { path: AUTH_LOGIN_PAGE.path, component: <Login/> },
  { path: AUTH_LOGOUT_PAGE.path, component: <Logout/> },
  { path: AUTH_SIGNUP_PAGE.path, component: <SignUp/> },
  { path: AUTH_RESET_PASSWORD_PAGE.path, component: <ResetPassword/> },
  { path: AUTH_FORGOT_PASSWORD_PAGE.path, component: <ForgotPassword/> },
];

export { USER_ROUTES, AUTH_ROUTES};
