import {useLocation, useNavigate} from "react-router-dom";
import {ProcessedVideoStateData, ScannerParameters, VideoObjectDetectionJsonData} from "../../../constants/types";
import {
    APP_CONFIG_DEFAULT,
    APP_INFERENCE_PAGE,
    INFERENCE_BRAND_SUB_MENU
} from "../../../config/config";
import React, {useEffect, useRef, useState} from "react";
import {AspectRatioType, ResponseType} from "../../../constants/enums/inference_enums";
import FetchService from "../../../services/FetchService";
import {Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import Breadcrumb from "../../../common/Breadcrumb";
import VideoAnalyserDataTableActionMenu from "./VideoAnalyserDataTableActionMenu";
import classnames from "classnames";
import DistributedColumnChartTable from "./Charts/DistributedColumnChartTable";
import StackedBarChartTable from "./Charts/StackedBarChartTable";
import VideoPlayer from "./VideoPlayer";
import {
    downloadProcessedVideo,
    downloadProcessedVideoCSV,
    downloadProcessedVideoJson
} from "../../../utils/video_inference_utils";
import {AuthUserData} from "../../../constants/models/Models";
import AuthUserService from "../../../services/AuthUserService";
import {BrandAnalyserTabType} from "../../../constants/enums/layout";
import {getFileNameFromUrl} from "../../../utils/utils";


const BrandAnalyserDataTable = () => {
    document.title = INFERENCE_BRAND_SUB_MENU.label + " | " + APP_CONFIG_DEFAULT.title;
    const navigate = useNavigate();
    const location = useLocation();
    const { processedVideo } = location.state || {};

    const videoRef = useRef<HTMLVideoElement>(null);
    const [aspectRatioType, setAspectRatioType] = useState<AspectRatioType>(AspectRatioType.RATIO_16x9); // useState<AspectRatioType>(AspectRatioType.RATIO_9x19);
    const [detectedJSONData, setDetectedJSONData] = useState<VideoObjectDetectionJsonData | null>(null);
    const [videoName, setVideoName] = useState<string>(INFERENCE_BRAND_SUB_MENU.label);
    const [brandAnalyserTabType, setBrandAnalyserTabType] = useState<BrandAnalyserTabType>(BrandAnalyserTabType.BRAND_SUMMARY);
    const [scannerParameters, setScannerParameters] = useState<ScannerParameters>({});

    const [isLoadingVideo, setIsLoadingVideo] = useState(true);

    const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();

    useEffect(() => {
        if (processedVideo) {
            loadVideoBoundingBoxData(processedVideo.urls.json).then(r => console.log());
            loadVideoData(processedVideo.urls.video).then(r => console.log());
        } else {
            navigate(APP_INFERENCE_PAGE.path);
        }
        }, []); // Empty dependency array ensures that this effect runs only once when the component mounts

    useEffect(() => {
        if (!isLoadingVideo && brandAnalyserTabType === BrandAnalyserTabType.VIDEO_PLAYER) videoRef.current!.play();

    }, [isLoadingVideo]);

    const videoAnalyserTabToggle = (tab: any) => {
        if (brandAnalyserTabType !== tab) setBrandAnalyserTabType(tab);

        const video: HTMLVideoElement | null = videoRef.current;
        if (tab === BrandAnalyserTabType.BRAND_SUMMARY) {
            if (video && video.readyState === 4 && !video.ended) {
                video.pause();
            }
        } else if (tab === BrandAnalyserTabType.VIDEO_PLAYER) {
            if (video && video.readyState === 4 && !video.ended) {
                video.play();
            }
        }
    };

    const loadVideoData = async (videoUrl: string) => {
        try {
            const response = await FetchService.downloadVideo(videoUrl, ResponseType.BLOB);
            if (response && response.status === 200) {
                const data = response.data;
                if (data && data instanceof Blob) {
                    const blob = new Blob([data], {type: data.type});
                    const objectURL = URL.createObjectURL(blob);
                    if (videoRef.current) {
                        videoRef.current!.src = objectURL;
                        videoRef.current!.load();
                        videoRef.current!.onloadedmetadata = () => {
                            setIsLoadingVideo(false);
                            // videoRef.current.play();
                            if (videoRef.current) {
                                const ratio = videoRef.current.videoWidth / videoRef.current.videoHeight;
                                if (ratio === 1) {
                                    setAspectRatioType(AspectRatioType.RATIO_1x1);
                                } else if (Math.abs(ratio - (4 / 3)) < 0.01) {
                                    setAspectRatioType(AspectRatioType.RATIO_4x3);
                                } else if (Math.abs(ratio - (9 / 16)) < 0.01) {
                                    setAspectRatioType(AspectRatioType.RATIO_9x16);
                                } else if (Math.abs(ratio - (21 / 9)) < 0.01) {
                                    setAspectRatioType(AspectRatioType.RATIO_21x9);
                                } else if (Math.abs(ratio - (9 / 19)) < 0.01) {
                                    setAspectRatioType(AspectRatioType.RATIO_9x19);
                                } else if (Math.abs(ratio - (19 / 9)) < 0.01) {
                                    setAspectRatioType(AspectRatioType.RATIO_19x9);
                                } else {
                                    setAspectRatioType(AspectRatioType.RATIO_16x9);
                                }
                            }
                        }
                    }
                }
            } else {
                console.error('Response is not a Blob:', response);
            }
        } catch (error) {
            console.error("Error download files:", error);
            console.error(error);
        }
    };

    const loadVideoBoundingBoxData = async (videoJsonUrl: string) => {
        try {
            const response = await FetchService.downloadJSON(videoJsonUrl);
            if (response && response.status === 200) {
                const data = response.data;
                if (data) {
                    const objectDetectionData: VideoObjectDetectionJsonData = data as VideoObjectDetectionJsonData;
                    setDetectedJSONData(objectDetectionData);
                    setVideoName(objectDetectionData.video_details.name)
                }
            } else {
                console.error('Response is not a Blob:', response);
            }
        } catch (error) {
            console.error("Error download files:", error);
            console.error(error);
        }
    };

    const handleDownloadVideo = async (processedVideo: ProcessedVideoStateData) => {
        await downloadProcessedVideo(processedVideo);
    }

    const handleDownloadCSV = async (processedVideo: ProcessedVideoStateData) => {
        await downloadProcessedVideoCSV(processedVideo, authUser, scannerParameters, true)
    }

    const handleDownloadJSON = async (processedVideo: ProcessedVideoStateData) => {
        await downloadProcessedVideoJson(processedVideo);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title={{label: APP_CONFIG_DEFAULT.title,  path: APP_CONFIG_DEFAULT.path}}
                                breadcrumbItem={{label: APP_INFERENCE_PAGE.label, path: APP_INFERENCE_PAGE.path}}
                                subBreadcrumbItem={{label:INFERENCE_BRAND_SUB_MENU.label, path:INFERENCE_BRAND_SUB_MENU.path, title: getFileNameFromUrl(processedVideo.urls.video)}}/>
                    <Row>
                        <Col lg={12} xl={12}>
                            <Card>
                                <CardBody>
                                    <VideoAnalyserDataTableActionMenu
                                        videoInference={processedVideo}
                                        onHandleDownloadVideo={handleDownloadVideo}
                                        onHandleExportCSV={handleDownloadCSV}
                                        onHandleDownloadJSON={handleDownloadJSON}
                                        parameters={scannerParameters}
                                        onUpdateScannerParameters={setScannerParameters}
                                    />
                                    <Row>
                                        <Nav tabs className="nav-tabs-custom">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: brandAnalyserTabType === BrandAnalyserTabType.BRAND_SUMMARY })}
                                                    onClick={() => { videoAnalyserTabToggle(BrandAnalyserTabType.BRAND_SUMMARY); }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="fas fa-home"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">{BrandAnalyserTabType.BRAND_SUMMARY}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: brandAnalyserTabType === BrandAnalyserTabType.VIDEO_PLAYER })}
                                                    onClick={() => { videoAnalyserTabToggle(BrandAnalyserTabType.VIDEO_PLAYER);}} >
                                                    <span className="d-block d-sm-none">
                                                        <i className="far fa-user"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">{BrandAnalyserTabType.VIDEO_PLAYER}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent activeTab={brandAnalyserTabType} className="p-3 text-muted">
                                            <TabPane tabId={BrandAnalyserTabType.BRAND_SUMMARY}>
                                                <StackedBarChartTable videoName={videoName} categoryName="Brand" xAxisLabel="Ad Number" yAxisLabel="Brand count" objectDetectionJsonData={detectedJSONData!} parameters={scannerParameters} authUser={authUser} />
                                            </TabPane>
                                            <TabPane tabId={BrandAnalyserTabType.VIDEO_PLAYER}>
                                                <VideoPlayer videoRef={videoRef} aspectRatioType={aspectRatioType} isLoadingVideo={isLoadingVideo} objectDetectionJsonData={detectedJSONData} authUser={authUser} parameters={scannerParameters} />
                                            </TabPane>
                                        </TabContent>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default BrandAnalyserDataTable;