import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {CategoryProps, CountryProps, ImageFileData} from "../../constants/types";
import {convertToBase64, formatBytes} from "../../utils/utils";
import {v4 as uuidv4} from "uuid";
import FetchService from "../../services/FetchService";
import FileUploadModal from "../Common/Modals/FileUploadModal";
import {InferenceType} from "../../constants/enums/all_enums";
import {notifyError, notifySuccess} from "../../services/NotificationService";
import authUserService from "../../services/AuthUserService";
import {InferenceState} from "../../constants/enums/inference_enums";

interface ImageInferenceDataTableMenuProps {
    activeBackend: boolean;
    onRunImageInferences: (e:any) => Promise<void>;
    onDownloadImageInferences: () => Promise<void>;
    onDeleteImageInferences: () => void;
    imageInferences: ImageFileData[];
    onUpdateImageInferences: (imageFileDataList: (prevSelectedImageFileData: ImageFileData[]) => ImageFileData[]) => void;
}

const ImageInferenceDataTableActionMenu = ({activeBackend, onRunImageInferences, onDownloadImageInferences, onDeleteImageInferences, imageInferences, onUpdateImageInferences}: ImageInferenceDataTableMenuProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);
    const handleImageUpload = async (files: File[], country: CountryProps, categories: CategoryProps[]) => {
        // const delay = 2000; // 2 seconds
        const inferenceParams: { [key: string]: any } = {};
        // Add Category value as well
        // inferenceParams['country'] = country.value;
        // inferenceParams['categories'] = categories.map(category => category.value);
        // TODO need to remove priority from next release
        inferenceParams['priority'] = 3;
        toggleModal();
        for (const file of files) {
            try {
                const encoded: string = (await convertToBase64(file)) as string;
                if (file.type.startsWith('image/')) {
                    const {name, size, type} = file;
                    // const dimensions = [img.width, img.height];
                    const imageData: ImageFileData = {
                        frame: uuidv4() as string,
                        name: name,
                        size: size,
                        sizeText: formatBytes(size),
                        type: type,
                        base64: encoded,
                        status: InferenceState.SELECTED,
                        uploadedDate: new Date(),
                        inference: undefined
                    };

                    const base64FileData = {frame: imageData.frame, image: {base64: imageData.base64}};
                    imageData.status = InferenceState.PROCESSING;
                    try {
                        const response = await FetchService.inference(base64FileData);
                        if (response && response.status===200) {
                            const data = response.data;
                            for (const responseFile of data) {
                                imageData.inference = responseFile.inference;
                                imageData.status = InferenceState.COMPLETED;
                            }
                            notifySuccess("Image inference has been processed successfully.");
                        }
                    } catch (error) {
                        imageData.status = InferenceState.FAILED;
                        notifyError("Image inference failed. Please try again.");
                    }
                    onUpdateImageInferences((prevSelectedFileData: ImageFileData[]) => [...prevSelectedFileData, imageData]);
                } else {
                    // Handle other file types if needed
                }
            } catch (error: any) {
                // Handle error
                notifyError("An unexpected error occurred during the image upload.");
            }
            // await sleep(delay);
        }
    };

    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col lg={1} md={1}>
                </Col>

                <Col lg={5} md={5}>
                </Col>

                <Col lg={6} md={6}>
                    <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <Button onClick={onRunImageInferences} color="btn btn-success-subtle" disabled={imageInferences?.every(fileData => fileData.status === InferenceState.COMPLETED) || imageInferences?.length == 0 || !activeBackend}>
                            Run All
                        </Button>

                        <Button onClick={onDownloadImageInferences} color="btn btn-success-subtle" disabled={!imageInferences?.some(fileData => fileData.status.includes(InferenceState.COMPLETED))}>
                            Download All
                        </Button>
                        <Button onClick={onDeleteImageInferences} color="btn btn-danger-subtle" disabled={imageInferences?.length == 0}>
                            Delete All
                        </Button>
                        <div className="text-sm-end">
                            <Button className="btn btn-primary-subtle" disabled={!activeBackend} onClick={toggleModal}>Upload Image</Button>
                        </div>
                    </div>
                </Col>
                <FileUploadModal isOpen={modalOpen} fileUploadType={InferenceType.IMAGE} toggleModal={toggleModal} title="Upload Image" handleUpload={handleImageUpload}/>
            </Row>
        </React.Fragment>
    );
};

export default ImageInferenceDataTableActionMenu;