import React from "react";
import { Link } from "react-router-dom";

//import components
import SidebarContent from "./SidebarContent";

//import images
import scannerLogoSm from "../assets/images/scanner-logo-sm.png";
import logoDark from "../assets/images/scanner-logo.png"
import {APP_CONFIG_DEFAULT} from "../config/config";
import { tToggle } from "../utils/utils";

interface LeftSidebarProps {
  type: any;
  theme: string;
  isMobile: boolean;
}

const LeftSidebar = (leftSidebarProps: LeftSidebarProps) => {

  return (
      <React.Fragment>
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to={APP_CONFIG_DEFAULT.path} className="logo logo-dark">
              <span className="logo-sm">
                <img src={scannerLogoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="22" />
              </span>
            </Link>
          </div>

          <button onClick={() => { tToggle(false); }} type="button" className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn" id="vertical-menu-btn">
            <i className="fa fa-fw fa-bars" />
          </button>

          <div className="h-100">
            {leftSidebarProps.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
          </div>
        </div>
    </React.Fragment>
  );
};

export default LeftSidebar;