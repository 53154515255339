import React, {useMemo} from "react";
import {ProcessedVideoStateData, ReactDataTableColumnProps} from "../../constants/types";
import {Button, Progress} from "reactstrap";
import {InferenceState} from "../../constants/enums/inference_enums";
import {AuthUserData} from "../../constants/models/Models";
import {INFERENCE_BRAND_SUB_MENU} from "../../config/config";
import {useNavigate} from "react-router-dom";
import {
    downloadProcessedVideo,
    downloadProcessedVideoCSV,
    downloadProcessedVideoJson
} from "../../utils/video_inference_utils";
import AuthUserService from "../../services/AuthUserService";
import {UserStatus} from "../../constants/enums/Auth";
import {ReactDataTableWithPagination} from "../Common/DataTable/ReactDataTableWithPagination";

interface InferenceDataTableProps {
    isDataLoading: boolean;
    activeBackend: boolean;
    inferences: ProcessedVideoStateData[];
    onDeleteInferenceVideo:(processedVideo: ProcessedVideoStateData) => Promise<void>;
}

export const InferenceDataTable = ({isDataLoading, activeBackend, inferences, onDeleteInferenceVideo}: InferenceDataTableProps) => {
    const navigate = useNavigate();
    const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
    const pendingUser= authUser && authUser.status?.toLowerCase() === UserStatus.PENDING.toLowerCase();

    const INFERENCE_COLUMNS: ReactDataTableColumnProps[] = useMemo(() =>
        [
            {
                Header: 'Name',
                Footer: 'Name',
                accessor: 'name',
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return (
                        <div className={`text-dark fw-medium ${eachCellData.state.toLowerCase() === InferenceState.COMPLETED ? 'processed-image' : ''}`}
                             onClick={() => eachCellData.state.toLowerCase() === InferenceState.COMPLETED ? handleViewBrandDetails(eachCellData) : null}
                        >
                            <i className="fas fa-play-circle align-middle text-danger me-2"></i>{" "}{eachCellData.name}
                        </div>
                    );
                },
            },
            {
                Header: 'Created Date',
                Footer: 'Created Date',
                accessor: 'time_created',
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return <div>{eachCellData.time_created}</div>
                    // return <div>{formatEpochTimestampDate(eachCellData.time_created!)}</div>
                },
            },
            {
                Header: 'Size',
                Footer: 'Size',
                accessor: 'size',
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return <div>{eachCellData.size}</div>
                    // return <div>{formatBytes(eachCellData.size)}</div>
                },
            },
            {
                Header: 'Status',
                Footer: 'Status',
                accessor: 'state',
                disableFilters: true,
                filterable: false,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return (<div>{eachCellData.state}</div>);
                }
            },
            {
                Header: 'Action',
                filterable: false,
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    return (
                        <div className="d-flex flex-wrap gap-1">
                            <Button color="btn btn-success-subtle" onClick={() => handleViewBrandDetails(eachCellData)}
                                    disabled={eachCellData.state.toLowerCase() !== InferenceState.COMPLETED}>
                                <i className="uil uil-eye me-2"></i>View
                            </Button>
                            <Button color="btn btn-secondary-subtle"
                                    disabled={eachCellData.state.toLowerCase() != InferenceState.COMPLETED}
                                    onClick={() => handleDownloadInferenceVideo(eachCellData)}><i
                                className="uil uil-download-alt me-2"></i>Download</Button>

                            <Button color="btn btn-secondary-subtle"
                                    disabled={eachCellData.state.toLowerCase() != InferenceState.COMPLETED}
                                    onClick={() => handleDownloadJSON(eachCellData)}><i
                                className="uil uil-brackets-curly me-2"></i>JSON
                            </Button>

                            <Button color="btn btn-secondary-subtle"
                                    disabled={eachCellData.state.toLowerCase() != InferenceState.COMPLETED}
                                    onClick={() => handleDownloadInferenceCSV(eachCellData)}><i
                                className="uil uil-file-download-alt me-2"></i>CSV
                            </Button>
                            <Button color="btn btn-danger-subtle"
                                    onClick={() => onDeleteInferenceVideo(eachCellData)}
                                    disabled={eachCellData.state.toLowerCase() !== InferenceState.COMPLETED && eachCellData.state.toLowerCase() !== InferenceState.FAILED && eachCellData.state.toLowerCase() !== InferenceState.PENDING}>
                                <i className="uil uil-trash-alt me-2"></i>Delete
                            </Button>
                        </div>
                    );
                },
            },
            {
                Header: " ",
                Footer: " ",
                disableFilters: true,
                filterable: true,
                style: {
                    width: '20%',
                },
                Cell: (cellProps: any) => {
                    const eachCellData = cellProps.row.original;
                    const progress = eachCellData.progress;
                    const status = eachCellData.state;
                    if (status.toLowerCase() !== InferenceState.COMPLETED && status.toLowerCase() !== InferenceState.FAILED && status.toLowerCase() !== InferenceState.PENDING) {
                        return (
                            <Progress value={progress} style={{height: '20px'}} color="info" className="bg-gradient" max={100}>
                                <b>{typeof progress === 'string' ? progress : progress + '%'}</b>
                            </Progress>
                        );
                    }
                }
            },
        ], []);

    const handleViewBrandDetails = (processedVideo: ProcessedVideoStateData) => {
        navigate(INFERENCE_BRAND_SUB_MENU.path, { state: { processedVideo }});
    }
    const handleDownloadInferenceVideo = async (processedVideo: ProcessedVideoStateData) => {
        await downloadProcessedVideo(processedVideo);
    }
    const handleDownloadInferenceCSV = async (processedVideo: ProcessedVideoStateData) => {
        await downloadProcessedVideoCSV(processedVideo, authUser, {}, true)
    }
    const handleDownloadJSON = async (processedVideo: ProcessedVideoStateData) => {
        await downloadProcessedVideoJson(processedVideo)
    }

    return (
        <React.Fragment>
            <ReactDataTableWithPagination columns={INFERENCE_COLUMNS} data={inferences} activeBackend={activeBackend} isDataLoading={isDataLoading} isPendingUser={pendingUser}/>
        </React.Fragment>
    );
};
