import React, { useEffect } from "react";

// withRouter
import withRouter from "../../common/withRouter";
import { logoutUser } from "../../slices/thunks";

//redux
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//image
import logoDark from "../../assets/images/scanner-logo.png";
import {
  APP_CONFIG_DEFAULT,
  AUTH_LOGIN_PAGE,
  AUTH_SIGNUP_PAGE
} from "../../config/config";
import {
  DEAKIN_NAME,
  DEAKIN_URL,
  IHT_DEAKIN_NAME,
  IHT_DEAKIN_URL,
  IISRI_DEAKIN_NAME,
  IISRI_DEAKIN_URL
} from "../../utils/url_helper";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(logoutUser(navigate));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>
                <div className="text-center py-5">
                  <div className="mb-4 mb-md-5">
                    <Link to={APP_CONFIG_DEFAULT.path} className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="22"
                        className="auth-logo-dark"
                      />
                    </Link>
                  </div>
                  <div className="mb-4 mb-md-5">
                    <div className="avatar-lg mx-auto">
                      <div className="avatar-title bg-light text-primary display-4 rounded-circle">
                        <i className="uil uil-user-circle"></i>
                      </div>
                    </div>
                  </div>
                  <div className="text-muted">
                    <h4 className="">You are Logged Out</h4>
                    <p>
                      Thank you for using{" "}
                      <span className="fw-semibold">{APP_CONFIG_DEFAULT.title}</span>
                    </p>
                    <div className="mt-4">
                      <Link to={AUTH_LOGIN_PAGE.path} className="btn btn-info w-100">
                        Sign In
                      </Link>
                    </div>
                  </div>

                  <div className="mt-5 text-center text-muted">
                    <p>
                      Don&apos;t have an account ?{" "}
                      <Link to={AUTH_SIGNUP_PAGE.path}
                            className="fw-medium text-decoration-underline">
                        {" "}
                        {AUTH_SIGNUP_PAGE.label}{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="text-center text-muted p-4">
                  <p className="mb-0">
                    &copy; {new Date().getFullYear()} {APP_CONFIG_DEFAULT.title}. Developed by <Link to={IISRI_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IISRI_DEAKIN_NAME}</Link>
                    {" "} and <Link to={IHT_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IHT_DEAKIN_NAME}</Link>,
                    {" "}<Link to={DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{DEAKIN_NAME}</Link>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Logout);
