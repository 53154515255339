// action
import { profileSuccess, profileError } from "./reducer";

import {
    postJwtProfile,
} from "../../../utils/fakebackend_helper"
import {getFirebaseAuthService} from "../../../utils/firebase_utils";
import FirebaseAuthService from "../../../services/FirebaseAuthService";
import {AuthUserData} from "../../../constants/models/Models";

export const editUserSettingProfile: any = (userData: AuthUserData, selectedPhoto: File) => async (dispatch: any) => {
    try {
        let response;
        const firebaseAuthService: FirebaseAuthService | null = getFirebaseAuthService();
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            response = firebaseAuthService!.updateUserSettingProfile(userData, selectedPhoto)
        } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            response = postJwtProfile(
                {
                    idx: userData.uid,
                }
            );
        }

        const data = await response;

        if (data) {
            dispatch(profileSuccess(data))
        }

    } catch (error) {
        dispatch(profileError(error))
    }
};