import React, {useEffect, useState} from "react";

import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {ImageFileData} from "../../constants/types";
import FetchService from "../../services/FetchService";
import { APP_CONFIG_DEFAULT, APP_IMAGE_INFERENCE_PAGE } from "../../config/config";
import {AxiosError} from "axios";
import ImageInferenceDataTableActionMenu from "./ImageInferenceDataTableActionMenu";
import JSZip from "jszip";
import {capitalize, formattedCurrentDate} from "../../utils/utils";
import ImageDataTableLightBox from "./ImageDataTableLightBox";
import {DeleteModal} from "../Common/Modals/DeleteModal";
import {notifyError, notifySuccess} from "../../services/NotificationService";
import Breadcrumb from "../../common/Breadcrumb";
import {AuthUserData} from "../../constants/models/Models";
import AuthUserService from "../../services/AuthUserService";
import {InferenceState} from "../../constants/enums/inference_enums";
import {UserStatus} from "../../constants/enums/Auth";

interface SelectedInferenceProps {
    fileName: string;
    index: number;
}

const ImageInference = () => {
    document.title = APP_IMAGE_INFERENCE_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;

    const [deleteInferencesModal, setDeleteInferencesModal] = useState<boolean>(false);
    const [deleteInferenceModal, setDeleteInferenceModal] = useState<boolean>(false);
    const [selectedInference, setSelectedInference] = useState<SelectedInferenceProps | null>(null);
    const [activeBackendMessage, setActiveBackendMessage] = useState('');

    const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
    const [activeBackend, setActiveBackend] = useState<boolean>(authUser.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase() && (authUser?.accessXApiKey !== "" || authUser.accessXApiKey !== undefined));

    const imageLocalStorage = "scanner-image-" + authUser?.uid;

    const [imageInferences, setImageInferences] = useState<ImageFileData[]>(() => {
        const scannerDataFromStorage = localStorage.getItem(imageLocalStorage);
        if (scannerDataFromStorage) return JSON.parse(scannerDataFromStorage);
        return [];
    });

    useEffect(() => {
        try {
            localStorage.setItem(imageLocalStorage, JSON.stringify(imageInferences));
            loadData(authUser?.uid).then();
        } catch (error: any) {
            if (error.code === 22) {
                console.error('LocalStorage quota exceeded. Unable to store data.');
                // Notify the user or implement another strategy to handle the error.
            } else {
                console.error('Error storing data in LocalStorage:', error);
            }
        }

    }, [imageInferences]);

    const loadData = async(userId: string) => {
        try {
            const response = await FetchService.getVideoByUserId(userId);
            setActiveBackend(true);
            setActiveBackendMessage('');
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response && (error.response.status === 401 || error.response.status === 500) || error.code === 'ERR_NETWORK') {
                    setActiveBackend(false);
                    if (error.response) setActiveBackendMessage(capitalize(error.response.statusText?.toLowerCase()));
                }
            }
        }
    };

    const onUpdateImageInferences = (imageInference: ImageFileData) => {
        if(!imageInference || imageInference ===null) return;
        setImageInferences(imageInferences.map((file: ImageFileData) => (file === imageInference ? imageInference : file)));
    };
    const handleDeleteImageInferencesModal = () => {
        toggleDeleteImageInferencesModal();
    };

    const handleDeleteImageInferences = async (inference: any) => {
        setImageInferences([]);
        toggleDeleteImageInferencesModal();
        notifySuccess("All image inferences have been deleted successfully.");
    }

    const handleDeleteImageInference = async (inferenceIndex: any) => {
        setImageInferences(prevInferences => {
            return prevInferences.filter((_, i) => i !== inferenceIndex);
        })
        setSelectedInference(null);
        notifySuccess("Image inference has been deleted successfully.");

        toggleDeleteInferenceModal();
    };

    const handleDeleteInferenceModal = (fileName:string, fileIndex: number) => {
        toggleDeleteInferenceModal();
        setSelectedInference({fileName:fileName, index: fileIndex})
    };

    const toggleDeleteImageInferencesModal = () => {
        setDeleteInferencesModal(!deleteInferencesModal);
        removeBodyCss();
    }

    const toggleDeleteInferenceModal = () => {
        setDeleteInferenceModal(!deleteInferenceModal);
        removeBodyCss();
    }

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }

    const handleImageInference = async (frame: string) => {
        const fileData = imageInferences.find((file: ImageFileData) => file.frame === frame);
        if (fileData) {
            const base64FileData = {frame: fileData.frame, image: {base64: fileData.base64}};
            fileData.status = InferenceState.PROCESSING;
            onUpdateImageInferences(fileData);
            try {
                const response = await FetchService.inference(base64FileData);
                if (response && response.status===200) {
                    const data = response.data;
                    for (const responseFile of data) {
                        const fileData = imageInferences.find((file: ImageFileData) => file.frame === responseFile.frame);
                        if (fileData) {
                            fileData.inference = responseFile.inference;
                            fileData.status = InferenceState.COMPLETED;
                            onUpdateImageInferences(fileData);
                        }
                    }
                    notifySuccess("Image inference has been processed successfully.");
                }
            } catch (error) {
                fileData.status = InferenceState.FAILED;
                onUpdateImageInferences(fileData);
                notifyError("An unexpected error occurred during the image inference processing.");

                if (error instanceof TypeError) {
                    // Network error
                    console.error('Network error:', error.message);
                } else if (error instanceof SyntaxError) {
                    // Parsing error
                    console.error('Parsing error:', error.message);
                } else if (error instanceof RangeError) {
                    // Timeout error
                    console.error('Timeout error:', error.message);
                } else if (error instanceof Error) {
                    // Other errors (server errors, CORS errors, etc.)
                    console.error('Error:', error.message);
                } else {
                    console.error('Unknown error:', error);
                }
            }
        }
    }

    const handleRunImageInferences = async (event: any) => {
        event.preventDefault();
        if (imageInferences.length === 0) return;
        const base64FileData = [];
        for (const file of imageInferences) {
            if(file.status !== InferenceState.COMPLETED) {
                base64FileData.push({frame: file.frame, image: {base64: file.base64}});
                let fileData = imageInferences.find((inputFile: ImageFileData) => inputFile.frame === file.frame);
                if (fileData) {
                    fileData.status = InferenceState.PROCESSING;
                    onUpdateImageInferences(fileData);
                }
            }
        }
        try {
            const response = await FetchService.inference(base64FileData);
            if (response && response.status===200) {
                const data = response.data;
                for (const responseFile of data) {
                    let fileData = imageInferences.find((file: ImageFileData) => file.frame === responseFile.frame);
                    if (fileData) {
                        fileData.inference = responseFile.inference;
                        fileData.status = InferenceState.COMPLETED;
                        onUpdateImageInferences(fileData);
                    }
                }
                notifySuccess("Image inferences have been processed successfully.");
            }
        } catch (error) {
            console.error(error);
            imageInferences.forEach((fileData:ImageFileData) => {
                fileData.status = InferenceState.FAILED;
                onUpdateImageInferences(fileData);
            });
            notifyError("An unexpected error occurred during the image inference processing.");
            if (error instanceof TypeError) {
                // Network error
                console.error('Network error:', error.message);
            } else if (error instanceof SyntaxError) {
                // Parsing error
                console.error('Parsing error:', error.message);
            } else if (error instanceof RangeError) {
                // Timeout error
                console.error('Timeout error:', error.message);
            } else if (error instanceof Error) {
                // Other errors (server errors, CORS errors, etc.)
                console.error('Error:', error.message);
            } else {
                console.error('Unknown error:', error);
            }
        }
    };

    const handleDownloadZipImageInferences = async () => {
        const zip = new JSZip();
        try {
            const successfulFiles = imageInferences.filter(fileData => fileData.status === InferenceState.COMPLETED);
            await Promise.all(successfulFiles.map(async (fileData, index) => {
                const response = await fetch(fileData?.inference?.image?.base64 ? fileData?.inference?.image?.base64 : fileData.base64);
                const blob = await response.blob();
                zip.file(`inference-${fileData.uploadedDate}-${fileData.name}`, blob);
            }));

            const content = await zip.generateAsync({type: 'blob'});
            const zipDownloadUrl = URL.createObjectURL(content);
            const zipDownloadLink = document.createElement('a');
            zipDownloadLink.href = zipDownloadUrl;
            zipDownloadLink.download = `Bundle inference images-${formattedCurrentDate()}.zip`;
            document.body.appendChild(zipDownloadLink);
            zipDownloadLink.click();
            document.body.removeChild(zipDownloadLink);
            URL.revokeObjectURL(zipDownloadUrl); // Free up memory
            notifySuccess("All image inferences have been downloaded successfully.");
        } catch (error) {
            console.error("Error handling zip:", error);
            notifyError("An unexpected error occurred during the image inference download.");
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title={{label: APP_CONFIG_DEFAULT.title,  path: APP_CONFIG_DEFAULT.path}}
                                breadcrumbItem={{label:APP_IMAGE_INFERENCE_PAGE.label, path:APP_IMAGE_INFERENCE_PAGE.path}}/>
                    <Row>
                        <Col lg={12} xl={12}>
                            <Card>
                                <CardBody>
                                    <ImageInferenceDataTableActionMenu
                                        activeBackend={activeBackend}
                                        onRunImageInferences={handleRunImageInferences}
                                        onDownloadImageInferences={handleDownloadZipImageInferences}
                                        onDeleteImageInferences={handleDeleteImageInferencesModal}
                                        imageInferences={imageInferences}
                                        onUpdateImageInferences={setImageInferences}
                                    />
                                    <ImageDataTableLightBox activeBackend={activeBackend} imageInferences={imageInferences} onDeleteImageInference={handleDeleteInferenceModal} onRunImageInference={handleImageInference}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {deleteInferenceModal && selectedInference &&
                    <DeleteModal isModalOpen={deleteInferenceModal} title="Delete Inference" bodyMessage={selectedInference?.fileName!}
                                 deleteActionLabel="Delete"
                                 toggleModal={toggleDeleteInferenceModal}
                                 deleteData={selectedInference?.index}
                                 handleDeleteAction={handleDeleteImageInference}
                    />
                }

                <DeleteModal isModalOpen={deleteInferencesModal} title="Delete All Inferences" bodyMessage="all inferences"
                             deleteActionLabel="Delete"
                             toggleModal={toggleDeleteImageInferencesModal}
                             deleteData={1}
                             handleDeleteAction={handleDeleteImageInferences}
                />
            </div>
        </React.Fragment>
    );
};

export default ImageInference;
