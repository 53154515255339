import {CategoryProps, CountryCategories, CountryProps} from "../constants/types";
import {CategoryCode, CountryCode} from "../constants/enums/all_enums";
import {RouteDefault, RouteMenuItem, SubMenuItem} from "./Menu";

export const CATEGORY_OPTIONS:CategoryProps[] = [
    {label:"Food", value:CategoryCode.FOOD},
    {label:"Alcohol", value:CategoryCode.ALCOHOL},
    {label:"Gambling", value:CategoryCode.GAMBLING},
    // {label:"Vaping", value:CategoryCode.VAPING},
];
export const COUNTRY_OPTIONS: CountryProps[] = [
    {label:"Australia", value: CountryCode.AU},
    {label:"UK", value:CountryCode.UK},
    {label:"Ireland", value:CountryCode.IE},
];
export const COUNTRY_CATEGORIES_OPTIONS: CountryCategories = {
    [CountryCode.AU]: [
        {label:"Food", value:CategoryCode.FOOD},
        {label:"Alcohol", value:CategoryCode.ALCOHOL},
        {label:"Gambling", value:CategoryCode.GAMBLING},
        // {label:"Vaping", value:CategoryCode.VAPING},
    ],
    [CountryCode.UK]: [
        {label:"Food", value:CategoryCode.FOOD},
        // {label:"Alcohol", value:CategoryCode.ALCOHOL},
        // {label:"Vaping", value:CategoryCode.VAPING},
        // {label:"Gambling", value:CategoryCode.GAMBLING},
    ],
    [CountryCode.IE]: [
        {label:"Food", value:CategoryCode.FOOD},
        // {label:"Alcohol", value:CategoryCode.ALCOHOL},
        // {label:"Vaping", value:CategoryCode.VAPING},
        // {label:"Gambling", value:CategoryCode.GAMBLING},
    ]
};


// Page and Subpages id, label, path and parentId configuration

export const APP_CONFIG_DEFAULT: RouteDefault  = { title: "Scanner", path:"/"};

const DASHBOARD_ID = 101;
export const APP_DASHBOARD_PAGE: RouteMenuItem = {
    id: DASHBOARD_ID,
    label: "Dashboard",
    icon: "monitor",
    path: "/dashboard"
};

// INFERENCE
const INFERENCE_ID = 102;
const INFERENCE_PATH = "/inference";
export const INFERENCE_BRAND_SUB_MENU : SubMenuItem = {
    id: INFERENCE_ID * 100 + 1,
    label: "Brand details",
    path: INFERENCE_PATH + "/brand",
    parentId: INFERENCE_ID
};

export const APP_INFERENCE_PAGE: RouteMenuItem = {
    id: INFERENCE_ID,
    label: "Video inference",
    icon: "video",
    path: INFERENCE_PATH,
    subItems: [INFERENCE_BRAND_SUB_MENU]
};

const IMAGE_INFERENCE_ID = 103;
export const APP_IMAGE_INFERENCE_PAGE: RouteMenuItem = {
    id: IMAGE_INFERENCE_ID,
    label: "Image inference",
    icon: "image",
    path: "/image"
};

const SETTINGS_MENU_ID = 104;
const SETTINGS_MENU_PATH = "/settings";
export const SETTINGS_MENU_USER_PROFILE : SubMenuItem = {
    id: SETTINGS_MENU_ID * 100 + 1,
    label: "Profile",
    path: SETTINGS_MENU_PATH + "/profile",
    parentId: SETTINGS_MENU_ID
};

export const SETTINGS_MENU_USER_EDIT : SubMenuItem = {
    id: SETTINGS_MENU_ID * 100 + 2,
    label: "Settings",
    path: SETTINGS_MENU_PATH + "/edit",
    parentId: SETTINGS_MENU_ID
};

const ADMIN_ID = 105;
const ADMIN_PATH = "/admin";

export const ADMIN_USER_SUB_PAGE : SubMenuItem = {
    id: ADMIN_ID * 100 + 1,
    label: "User details",
    path: ADMIN_PATH + "/user",
    parentId: ADMIN_ID
};

export const ADMIN_USER_EDIT_SUB_PAGE : SubMenuItem = {
    id: ADMIN_ID * 100 + 2,
    label: "Edit user",
    path: ADMIN_PATH + "/user-edit",
    parentId: ADMIN_ID
};

export const APP_ADMIN_PAGE: RouteMenuItem =  {
    id: ADMIN_ID,
    label: "Admin panel",
    icon: "monitor",
    path: ADMIN_PATH,
    subItems: [ADMIN_USER_SUB_PAGE, ADMIN_USER_EDIT_SUB_PAGE]
}

export const AUTH_LOGIN_PAGE: RouteMenuItem =  {
    id: 1,
    label: "Sign in",
    icon: "monitor",
    path: "/login"
};

export const AUTH_SIGNUP_PAGE: RouteMenuItem =  {
    id: 2,
    label: "Sign up",
    icon: "monitor",
    path: "/signup"
};

export const AUTH_LOGOUT_PAGE: RouteMenuItem =  {
    id: 3,
    label: "Logout",
    icon: "monitor",
    path: "/logout"
};

export const AUTH_FORGOT_PASSWORD_PAGE: RouteMenuItem =  {
    id: 4,
    label: "Password forget",
    icon: "monitor",
    path: "/forgot-password"
};

export const AUTH_RESET_PASSWORD_PAGE: RouteMenuItem =  {
    id: 5,
    label: "Password reset",
    icon: "monitor",
    path: "/reset-password"
};